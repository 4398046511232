import React, { useEffect, useState, useRef } from 'react'
import { FormLabel } from '@material-ui/core'

const ImagePicker = (props) => {
	const fileRef = useRef()

	const [preview, setPreview] = useState(props.preview || null)
	const [image, setImage] = useState('')

	useEffect(() => {
		if (!image && !preview && props.preview) {
			setPreview(props.preview)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.preview])

	const onPick = (data) => {
		const [file] = data.target.files
		setImage(file)
		const reader = new FileReader()
		reader.onload = e => {
			setPreview(e.target.result)
			props && props.onChange && props.onChange(file, e.target.result)
		}
		reader.readAsDataURL(file)
	}

	return (
		<div className='image-picker2'>
			<FormLabel>{ props.label || 'Picture' }</FormLabel>
			<div className='image-picker-container' onClick={ () => fileRef.current.click() }>
				{ preview ?
					<img
						className='image-preview'
						src={preview}
						alt='Project Preview'
					/>
					:
					<i className='icon upload' style={{zIndex: 1000000}}/>
				}
				<input
					type='file'
					name='file'
					accept='image/*'
					ref={fileRef}
					style={{ display: 'none' }}
					onChange={onPick} />
			</div>
		</div>
	)
}

export default ImagePicker

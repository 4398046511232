import React from "react";
import { withRouter } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { Card } from "semantic-ui-react";

import { listUsers } from "../../graphql/queries";
import { createUserStudios, deleteUserStudios } from "../../graphql/mutations";

import { UserContext } from "../../App";

import "../../css/StudioMembers.css";
import "../../css/v2/Card.css";

class StudioMembers extends React.Component {
  initalState = {
    studioInfo: this.props.studioData,
    visible: false,
    openAddMembers: false,
    addMembers: [],
    value: "",
    sampleMembers: this.props.studioData.members.items,
  };
  state = {
    studioInfo: this.props.studioData,
    visible: false,
    openAddMembers: false,
    addMembers: [],
    value: "",
    sampleMembers: this.props.studioData.members.items,
  };

  handleSelect() {
    this.setState({
      addMembers: [...this.state.addMembers, this.state.value],
      value: "",
    });
  }

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.setState({
        addMembers: [...this.state.addMembers, this.state.value],
        value: "",
      });
    }
  };

  handleTagClose(tag) {
    var newMembers = this.state.addMembers;
    newMembers = newMembers.filter((ele) => {
      return ele !== tag;
    });
    this.setState({ addMembers: newMembers });
    // return newMembers;
  }

  handleAddMembers(user) {
    const addMembersCopy1 = [...new Set(this.state.addMembers)];
    const addMembersCopy = addMembersCopy1.filter(
      (x) =>
        this.state.sampleMembers.filter((y) => y.user.username === x).length === 0
    );
    this.setState({ openAddMembers: false, addMembers: [] });
    console.log("add members:", this.state.addMembers);
    addMembersCopy.map(async (member) => {
      const result = await API.graphql(
        graphqlOperation(listUsers, {
          // filter: {
          //   or: [{ username: { match: member } }],
          // },
        })
      );
      console.log(result);
      if (result.data.searchUsers.items.length > 0) {
        const userStudioInput = {
          userStudiosUserId: result.data.searchUsers.items[0].id,
          userStudiosStudioId: this.state.studioInfo.id,
        };
        const userStudio = await API.graphql(
          graphqlOperation(createUserStudios, { input: userStudioInput })
        );
        console.log({ userStudio });
      }
    });
  }

  handleRemoveMember = async (user, userStudioId) => {
    const userStudioInput = {
      id: userStudioId,
    };
    const userStudio = await API.graphql(
      graphqlOperation(deleteUserStudios, { input: userStudioInput })
    );
    console.log({ userStudio });
  };

  render() {
    const {
      sampleMembers,
      studioInfo,
    } = this.state;

    return (
      <UserContext.Consumer>
        {({ user, userAttributes }) => {
          const isStudioOwner =
            userAttributes && userAttributes.sub === studioInfo.owner.id;

          return (
            <div>
              <Card.Group>
                {sampleMembers.map((member, index) =>
                  <Card key={index} className="ui member">
                    <Card.Content>
                      <Card.Meta>
                        <b>{member.user.name}</b>
                        <div>{member.user.id === studioInfo.owner.id ? "Owner" : "Member"}</div>
                      </Card.Meta>
                    </Card.Content>
                    {isStudioOwner && member.userID !== studioInfo.owner.id && (
                      <Card.Content extra className="extra-remove">
                        <button
                          className="ui fluid button remove-button"
                          onClick={() =>
                            this.handleRemoveMember(user, member.id)
                          }
                        >
                          Remove
                        </button>
                      </Card.Content>
                    )}
                  </Card>
                )}
              </Card.Group>
            </div>
          );
        }}
      </UserContext.Consumer>
    );
  }
}

export default withRouter(StudioMembers);

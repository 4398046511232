import React from 'react'
import { withRouter } from 'react-router-dom'
import { Card } from 'semantic-ui-react'
import { API, Auth, graphqlOperation } from 'aws-amplify'

import { UserContext } from '../../App'
import ProjectDialog from '../project/ProjectDialog'
import ProjectCard from '../project/ProjectCard'

import { translateDataX } from '../../xAPI/DataX'
import { saveToLRS } from '../../xAPI'

import { updateProject } from '../../graphql/mutations'

import '../../css/StudioProjects.css'
import '../../css/v2/Card.css'

class StudioProjects extends React.Component {
	state = {
		addProjectDialog: false
	}

	checkStudioMember = (studioInfo, user) => {
		const checkResult = studioInfo.members.items.filter(
			(member) => member.user.id === user.attributes.sub
		)

		return checkResult.length !== 0
	}

	setProjectAsFeatured = async (projectId, featured) => {
		try {
			const projectInput = {
				id: projectId,
				featured: featured
			}
			await API.graphql(
				graphqlOperation(updateProject, { input: projectInput })
			)
			const user = await Auth.currentAuthenticatedUser()
			saveToLRS(user.attributes, translateDataX('edit', 'project'), projectId)
		} catch (err) {
			console.error(`Failed to update project [${projectId}]`, err)
		}
	}

	render() {
		const studioProjectData = (this.props.studioProjects.items || []).sort((a, b) => b.featured - a.featured)
		const { studioId, studioData } = this.props
		const { addProjectDialog } = this.state

		return (
			<UserContext.Consumer>
				{({ user }) => (
					<div>
						<Card.Group className='prof-project-list' style={{ minHeight: '200px' }}>
							{this.checkStudioMember(studioData, user) && (
								<Card
									className='create-project ui link'
									onClick={() =>
										this.setState({
											...this.initialState,
											addProjectDialog: true,
										})
									}
								>
									<p className='add-project'>+</p>
								</Card>
							)}
							{ studioProjectData
									.slice(0, this.props.limitItems)
									.map((studioPro, index) =>
								<ProjectCard
									key={index}
									project={studioPro}
									onClickFeatured={this.props.isOwner ? this.setProjectAsFeatured : null} />) }
						</Card.Group>
						{/* Project Modal */}
						{addProjectDialog &&
							<ProjectDialog
								studioId={studioId}
								onClose={() => this.setState({ addProjectDialog: false })}
							/>
						}
					</div>
				)}
			</UserContext.Consumer>
		)
	}
}

export default withRouter(StudioProjects)

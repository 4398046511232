import React, { useState } from 'react'
import { API, Auth, Storage, graphqlOperation } from 'aws-amplify'

import { createProjectComponent } from '../../../graphql/mutations'
import aws_exports from '../../../aws-exports'

import BlockIcon from './BlockIcon'

const DrawIcon = (props) => {
	const [loading, setLoading] = useState(false)

	const handleOnClick = async () => {
		const { identityId } = await Auth.currentCredentials()
		const { project } = props

		if (!project || !project.id) {
			return console.error('Did not receive project ID')
		}

		const tldr = {}

		setLoading(true)

		const uploadedFile = await Storage.put(
			`/public/${identityId}/${project.id}-${Date.now()}.tldr`,
			JSON.stringify(tldr),
			{ level: 'protected' }
		)
		if (!uploadedFile || !uploadedFile.key) {
			setLoading(false)
			return console.error('Could not upload .tldr file')
		}

		const result = await API.graphql(graphqlOperation(createProjectComponent, {
			input: {
				type: 'DRAW',
				file: {
					key: uploadedFile.key,
					bucket: aws_exports.aws_user_files_s3_bucket,
					region: aws_exports.aws_project_region,
				},
				projectID: project.id
			}
		}))

		props.onProjectComponentCreated && props.onProjectComponentCreated(result)

		setLoading(false)
	}

	return <BlockIcon title='Draw' icon='pencil' onClick={ handleOnClick } disabled={ props.disabled } loading={ loading } />
}

export default DrawIcon
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { Button, CircularProgress, Grid, TextField, Typography } from '@material-ui/core'
import Notification from '../components/ui/Notification'

const ValidatePage = (props) => {
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState()
	const [email, setEmail] = useState()
	const [validEmail, setValidEmail] = useState()
	const [code, setCode] = useState()

	const confirmSignup = async (code) => {
		setLoading(true)
		try {
			await Auth.confirmSignUp(email, code)
			Notification({
				title: 'Success',
				message: 'Account validated!',
				type: 'success',
			})
			props.history.push('/')
		} catch (err) {
			setLoading(false)
			setError(err && err.message ? err.message : 'An unexpected error occurred')
		}
	}

	return (
		<div style={{ textAlign: 'center', padding: 50, maxWidth: '500px', margin: '0 auto' }}>
			<h2>Validate Account</h2>
			<form>
				<TextField
					label='Email address'
					type='email'
					variant='outlined'
					fullWidth
					style={styles.textField}
					defaultValue={email}
					error={email != null && !validEmail}
					onChange={e => {
						setEmail(e.target.value)
						setValidEmail(isEmail(e.target.value))
					}}
					required />
				<TextField
					label='Verification code'
					type='text'
					variant='outlined'
					fullWidth
					style={styles.textField}
					defaultValue={code}
					onChange={e => setCode(e.target.value)}
					required />
				<Grid container justifyContent='flex-end' style={styles.textField}>
					<Button
						onClick={() => confirmSignup(code)}
						disabled={loading || !validEmail}
						variant='contained'>{loading ? <CircularProgress size={18} /> : 'Confirm Signup'}</Button>
				</Grid>
				{error &&
					<Typography variant='subtitle1' color='error'>{error}</Typography>
				}
			</form>
			<Link to={'/'}>Go Home</Link>
		</div>
	)
}

// TODO: move this to `utils`
const isEmail = email => {
	return /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(email)
}

const styles = {
	textField: {
		margin: '0.5em 0'
	}
}

export default ValidatePage


import TextLoop from './ui/TextLoop'

import background from '../assets/codapmini-1.png'

import '../css/Header.css'

const Hero = ({ onClickLogin, onClickNewProject }) => {
	return (
		<div className='studio-header header-style intro-header'>
			<img className='background-img'
				src={background}
				alt='DataX background' />
			<h1 className='header-title'>
				DataX
				<TextLoop
					interval={2000}>
					<span className='header-title-sub'>Environment</span>
					<span className='header-title-sub'>Government</span>
					<span className='header-title-sub'>Community</span>
					<span className='header-title-sub'> School</span>
					<span className='header-title-sub'> Planet</span>
				</TextLoop>
			</h1>
			<h3 className='header-subtitle'>Launch your data investigations today!</h3>
			{ onClickLogin &&
			<div className='header-button'>
				<button className='ui button nav-btn' onClick={onClickLogin} >Get Started</button>
			</div>
			}
			{ onClickNewProject &&
			<div className='header-button'>
				<button className='ui button nav-btn' onClick={onClickNewProject} >Create Project</button>
			</div>
			}
		</div>
	)
}

export default Hero

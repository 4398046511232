/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      email
      name
      avatar {
        bucket
        region
        key
        __typename
      }
      registered
      ownProject {
        items {
          id
          name
          description
          complexity
          file {
            bucket
            region
            key
            __typename
          }
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          parentID
          parent {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          children {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          components {
            items {
              id
              type
              file {
                bucket
                region
                key
                __typename
              }
              projectID
              project {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              state
              index
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          type
          featured
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      studio {
        items {
          id
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          user {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      email
      name
      avatar {
        bucket
        region
        key
        __typename
      }
      registered
      ownProject {
        items {
          id
          name
          description
          complexity
          file {
            bucket
            region
            key
            __typename
          }
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          parentID
          parent {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          children {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          components {
            items {
              id
              type
              file {
                bucket
                region
                key
                __typename
              }
              projectID
              project {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              state
              index
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          type
          featured
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      studio {
        items {
          id
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          user {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      email
      name
      avatar {
        bucket
        region
        key
        __typename
      }
      registered
      ownProject {
        items {
          id
          name
          description
          complexity
          file {
            bucket
            region
            key
            __typename
          }
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          parentID
          parent {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          children {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          components {
            items {
              id
              type
              file {
                bucket
                region
                key
                __typename
              }
              projectID
              project {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              state
              index
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          type
          featured
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      studio {
        items {
          id
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          user {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStudio = /* GraphQL */ `
  mutation CreateStudio(
    $input: CreateStudioInput!
    $condition: ModelStudioConditionInput
  ) {
    createStudio(input: $input, condition: $condition) {
      id
      name
      description
      coverImage {
        bucket
        region
        key
        __typename
      }
      members {
        items {
          id
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          user {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      projects {
        items {
          id
          name
          description
          complexity
          file {
            bucket
            region
            key
            __typename
          }
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          parentID
          parent {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          children {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          components {
            items {
              id
              type
              file {
                bucket
                region
                key
                __typename
              }
              projectID
              project {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              state
              index
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          type
          featured
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      owner {
        id
        username
        email
        name
        avatar {
          bucket
          region
          key
          __typename
        }
        registered
        ownProject {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        studio {
          items {
            id
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            user {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      type
      createdAt
      updatedAt
      studioOwnerId
      __typename
    }
  }
`;
export const updateStudio = /* GraphQL */ `
  mutation UpdateStudio(
    $input: UpdateStudioInput!
    $condition: ModelStudioConditionInput
  ) {
    updateStudio(input: $input, condition: $condition) {
      id
      name
      description
      coverImage {
        bucket
        region
        key
        __typename
      }
      members {
        items {
          id
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          user {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      projects {
        items {
          id
          name
          description
          complexity
          file {
            bucket
            region
            key
            __typename
          }
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          parentID
          parent {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          children {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          components {
            items {
              id
              type
              file {
                bucket
                region
                key
                __typename
              }
              projectID
              project {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              state
              index
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          type
          featured
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      owner {
        id
        username
        email
        name
        avatar {
          bucket
          region
          key
          __typename
        }
        registered
        ownProject {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        studio {
          items {
            id
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            user {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      type
      createdAt
      updatedAt
      studioOwnerId
      __typename
    }
  }
`;
export const deleteStudio = /* GraphQL */ `
  mutation DeleteStudio(
    $input: DeleteStudioInput!
    $condition: ModelStudioConditionInput
  ) {
    deleteStudio(input: $input, condition: $condition) {
      id
      name
      description
      coverImage {
        bucket
        region
        key
        __typename
      }
      members {
        items {
          id
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          user {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      projects {
        items {
          id
          name
          description
          complexity
          file {
            bucket
            region
            key
            __typename
          }
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          parentID
          parent {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          children {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          components {
            items {
              id
              type
              file {
                bucket
                region
                key
                __typename
              }
              projectID
              project {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              state
              index
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          type
          featured
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      owner {
        id
        username
        email
        name
        avatar {
          bucket
          region
          key
          __typename
        }
        registered
        ownProject {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        studio {
          items {
            id
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            user {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      type
      createdAt
      updatedAt
      studioOwnerId
      __typename
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      name
      description
      complexity
      file {
        bucket
        region
        key
        __typename
      }
      studioID
      studio {
        id
        name
        description
        coverImage {
          bucket
          region
          key
          __typename
        }
        members {
          items {
            id
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            user {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        projects {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        owner {
          id
          username
          email
          name
          avatar {
            bucket
            region
            key
            __typename
          }
          registered
          ownProject {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          studio {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        type
        createdAt
        updatedAt
        studioOwnerId
        __typename
      }
      userID
      owner {
        id
        username
        email
        name
        avatar {
          bucket
          region
          key
          __typename
        }
        registered
        ownProject {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        studio {
          items {
            id
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            user {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      parentID
      parent {
        id
        name
        description
        complexity
        file {
          bucket
          region
          key
          __typename
        }
        studioID
        studio {
          id
          name
          description
          coverImage {
            bucket
            region
            key
            __typename
          }
          members {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          projects {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          type
          createdAt
          updatedAt
          studioOwnerId
          __typename
        }
        userID
        owner {
          id
          username
          email
          name
          avatar {
            bucket
            region
            key
            __typename
          }
          registered
          ownProject {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          studio {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        parentID
        parent {
          id
          name
          description
          complexity
          file {
            bucket
            region
            key
            __typename
          }
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          parentID
          parent {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          children {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          components {
            items {
              id
              type
              file {
                bucket
                region
                key
                __typename
              }
              projectID
              project {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              state
              index
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          type
          featured
          createdAt
          updatedAt
          __typename
        }
        children {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        components {
          items {
            id
            type
            file {
              bucket
              region
              key
              __typename
            }
            projectID
            project {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            state
            index
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        featured
        createdAt
        updatedAt
        __typename
      }
      children {
        items {
          id
          name
          description
          complexity
          file {
            bucket
            region
            key
            __typename
          }
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          parentID
          parent {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          children {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          components {
            items {
              id
              type
              file {
                bucket
                region
                key
                __typename
              }
              projectID
              project {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              state
              index
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          type
          featured
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      components {
        items {
          id
          type
          file {
            bucket
            region
            key
            __typename
          }
          projectID
          project {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          state
          index
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      type
      featured
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      name
      description
      complexity
      file {
        bucket
        region
        key
        __typename
      }
      studioID
      studio {
        id
        name
        description
        coverImage {
          bucket
          region
          key
          __typename
        }
        members {
          items {
            id
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            user {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        projects {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        owner {
          id
          username
          email
          name
          avatar {
            bucket
            region
            key
            __typename
          }
          registered
          ownProject {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          studio {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        type
        createdAt
        updatedAt
        studioOwnerId
        __typename
      }
      userID
      owner {
        id
        username
        email
        name
        avatar {
          bucket
          region
          key
          __typename
        }
        registered
        ownProject {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        studio {
          items {
            id
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            user {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      parentID
      parent {
        id
        name
        description
        complexity
        file {
          bucket
          region
          key
          __typename
        }
        studioID
        studio {
          id
          name
          description
          coverImage {
            bucket
            region
            key
            __typename
          }
          members {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          projects {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          type
          createdAt
          updatedAt
          studioOwnerId
          __typename
        }
        userID
        owner {
          id
          username
          email
          name
          avatar {
            bucket
            region
            key
            __typename
          }
          registered
          ownProject {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          studio {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        parentID
        parent {
          id
          name
          description
          complexity
          file {
            bucket
            region
            key
            __typename
          }
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          parentID
          parent {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          children {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          components {
            items {
              id
              type
              file {
                bucket
                region
                key
                __typename
              }
              projectID
              project {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              state
              index
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          type
          featured
          createdAt
          updatedAt
          __typename
        }
        children {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        components {
          items {
            id
            type
            file {
              bucket
              region
              key
              __typename
            }
            projectID
            project {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            state
            index
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        featured
        createdAt
        updatedAt
        __typename
      }
      children {
        items {
          id
          name
          description
          complexity
          file {
            bucket
            region
            key
            __typename
          }
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          parentID
          parent {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          children {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          components {
            items {
              id
              type
              file {
                bucket
                region
                key
                __typename
              }
              projectID
              project {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              state
              index
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          type
          featured
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      components {
        items {
          id
          type
          file {
            bucket
            region
            key
            __typename
          }
          projectID
          project {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          state
          index
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      type
      featured
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      name
      description
      complexity
      file {
        bucket
        region
        key
        __typename
      }
      studioID
      studio {
        id
        name
        description
        coverImage {
          bucket
          region
          key
          __typename
        }
        members {
          items {
            id
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            user {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        projects {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        owner {
          id
          username
          email
          name
          avatar {
            bucket
            region
            key
            __typename
          }
          registered
          ownProject {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          studio {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        type
        createdAt
        updatedAt
        studioOwnerId
        __typename
      }
      userID
      owner {
        id
        username
        email
        name
        avatar {
          bucket
          region
          key
          __typename
        }
        registered
        ownProject {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        studio {
          items {
            id
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            user {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      parentID
      parent {
        id
        name
        description
        complexity
        file {
          bucket
          region
          key
          __typename
        }
        studioID
        studio {
          id
          name
          description
          coverImage {
            bucket
            region
            key
            __typename
          }
          members {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          projects {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          type
          createdAt
          updatedAt
          studioOwnerId
          __typename
        }
        userID
        owner {
          id
          username
          email
          name
          avatar {
            bucket
            region
            key
            __typename
          }
          registered
          ownProject {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          studio {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        parentID
        parent {
          id
          name
          description
          complexity
          file {
            bucket
            region
            key
            __typename
          }
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          parentID
          parent {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          children {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          components {
            items {
              id
              type
              file {
                bucket
                region
                key
                __typename
              }
              projectID
              project {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              state
              index
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          type
          featured
          createdAt
          updatedAt
          __typename
        }
        children {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        components {
          items {
            id
            type
            file {
              bucket
              region
              key
              __typename
            }
            projectID
            project {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            state
            index
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        featured
        createdAt
        updatedAt
        __typename
      }
      children {
        items {
          id
          name
          description
          complexity
          file {
            bucket
            region
            key
            __typename
          }
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          parentID
          parent {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          children {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          components {
            items {
              id
              type
              file {
                bucket
                region
                key
                __typename
              }
              projectID
              project {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              state
              index
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          type
          featured
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      components {
        items {
          id
          type
          file {
            bucket
            region
            key
            __typename
          }
          projectID
          project {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          state
          index
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      type
      featured
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProjectComponent = /* GraphQL */ `
  mutation CreateProjectComponent(
    $input: CreateProjectComponentInput!
    $condition: ModelProjectComponentConditionInput
  ) {
    createProjectComponent(input: $input, condition: $condition) {
      id
      type
      file {
        bucket
        region
        key
        __typename
      }
      projectID
      project {
        id
        name
        description
        complexity
        file {
          bucket
          region
          key
          __typename
        }
        studioID
        studio {
          id
          name
          description
          coverImage {
            bucket
            region
            key
            __typename
          }
          members {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          projects {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          type
          createdAt
          updatedAt
          studioOwnerId
          __typename
        }
        userID
        owner {
          id
          username
          email
          name
          avatar {
            bucket
            region
            key
            __typename
          }
          registered
          ownProject {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          studio {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        parentID
        parent {
          id
          name
          description
          complexity
          file {
            bucket
            region
            key
            __typename
          }
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          parentID
          parent {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          children {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          components {
            items {
              id
              type
              file {
                bucket
                region
                key
                __typename
              }
              projectID
              project {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              state
              index
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          type
          featured
          createdAt
          updatedAt
          __typename
        }
        children {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        components {
          items {
            id
            type
            file {
              bucket
              region
              key
              __typename
            }
            projectID
            project {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            state
            index
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        featured
        createdAt
        updatedAt
        __typename
      }
      state
      index
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProjectComponent = /* GraphQL */ `
  mutation UpdateProjectComponent(
    $input: UpdateProjectComponentInput!
    $condition: ModelProjectComponentConditionInput
  ) {
    updateProjectComponent(input: $input, condition: $condition) {
      id
      type
      file {
        bucket
        region
        key
        __typename
      }
      projectID
      project {
        id
        name
        description
        complexity
        file {
          bucket
          region
          key
          __typename
        }
        studioID
        studio {
          id
          name
          description
          coverImage {
            bucket
            region
            key
            __typename
          }
          members {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          projects {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          type
          createdAt
          updatedAt
          studioOwnerId
          __typename
        }
        userID
        owner {
          id
          username
          email
          name
          avatar {
            bucket
            region
            key
            __typename
          }
          registered
          ownProject {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          studio {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        parentID
        parent {
          id
          name
          description
          complexity
          file {
            bucket
            region
            key
            __typename
          }
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          parentID
          parent {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          children {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          components {
            items {
              id
              type
              file {
                bucket
                region
                key
                __typename
              }
              projectID
              project {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              state
              index
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          type
          featured
          createdAt
          updatedAt
          __typename
        }
        children {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        components {
          items {
            id
            type
            file {
              bucket
              region
              key
              __typename
            }
            projectID
            project {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            state
            index
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        featured
        createdAt
        updatedAt
        __typename
      }
      state
      index
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProjectComponent = /* GraphQL */ `
  mutation DeleteProjectComponent(
    $input: DeleteProjectComponentInput!
    $condition: ModelProjectComponentConditionInput
  ) {
    deleteProjectComponent(input: $input, condition: $condition) {
      id
      type
      file {
        bucket
        region
        key
        __typename
      }
      projectID
      project {
        id
        name
        description
        complexity
        file {
          bucket
          region
          key
          __typename
        }
        studioID
        studio {
          id
          name
          description
          coverImage {
            bucket
            region
            key
            __typename
          }
          members {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          projects {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          type
          createdAt
          updatedAt
          studioOwnerId
          __typename
        }
        userID
        owner {
          id
          username
          email
          name
          avatar {
            bucket
            region
            key
            __typename
          }
          registered
          ownProject {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          studio {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        parentID
        parent {
          id
          name
          description
          complexity
          file {
            bucket
            region
            key
            __typename
          }
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          parentID
          parent {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          children {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          components {
            items {
              id
              type
              file {
                bucket
                region
                key
                __typename
              }
              projectID
              project {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              state
              index
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          type
          featured
          createdAt
          updatedAt
          __typename
        }
        children {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        components {
          items {
            id
            type
            file {
              bucket
              region
              key
              __typename
            }
            projectID
            project {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            state
            index
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        featured
        createdAt
        updatedAt
        __typename
      }
      state
      index
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserStudios = /* GraphQL */ `
  mutation CreateUserStudios(
    $input: CreateUserStudiosInput!
    $condition: ModelUserStudiosConditionInput
  ) {
    createUserStudios(input: $input, condition: $condition) {
      id
      studioID
      studio {
        id
        name
        description
        coverImage {
          bucket
          region
          key
          __typename
        }
        members {
          items {
            id
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            user {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        projects {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        owner {
          id
          username
          email
          name
          avatar {
            bucket
            region
            key
            __typename
          }
          registered
          ownProject {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          studio {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        type
        createdAt
        updatedAt
        studioOwnerId
        __typename
      }
      userID
      user {
        id
        username
        email
        name
        avatar {
          bucket
          region
          key
          __typename
        }
        registered
        ownProject {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        studio {
          items {
            id
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            user {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserStudios = /* GraphQL */ `
  mutation UpdateUserStudios(
    $input: UpdateUserStudiosInput!
    $condition: ModelUserStudiosConditionInput
  ) {
    updateUserStudios(input: $input, condition: $condition) {
      id
      studioID
      studio {
        id
        name
        description
        coverImage {
          bucket
          region
          key
          __typename
        }
        members {
          items {
            id
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            user {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        projects {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        owner {
          id
          username
          email
          name
          avatar {
            bucket
            region
            key
            __typename
          }
          registered
          ownProject {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          studio {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        type
        createdAt
        updatedAt
        studioOwnerId
        __typename
      }
      userID
      user {
        id
        username
        email
        name
        avatar {
          bucket
          region
          key
          __typename
        }
        registered
        ownProject {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        studio {
          items {
            id
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            user {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserStudios = /* GraphQL */ `
  mutation DeleteUserStudios(
    $input: DeleteUserStudiosInput!
    $condition: ModelUserStudiosConditionInput
  ) {
    deleteUserStudios(input: $input, condition: $condition) {
      id
      studioID
      studio {
        id
        name
        description
        coverImage {
          bucket
          region
          key
          __typename
        }
        members {
          items {
            id
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            user {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        projects {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        owner {
          id
          username
          email
          name
          avatar {
            bucket
            region
            key
            __typename
          }
          registered
          ownProject {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          studio {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        type
        createdAt
        updatedAt
        studioOwnerId
        __typename
      }
      userID
      user {
        id
        username
        email
        name
        avatar {
          bucket
          region
          key
          __typename
        }
        registered
        ownProject {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        studio {
          items {
            id
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            user {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

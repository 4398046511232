
import React, { useState } from 'react'
import ReactDOM from 'react-dom';
import { Fade, Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab'

const Notification = ({ title = '', message, type = 'success', duration = 2000 }) => {
	const div = document.createElement('div');
	document.body.appendChild(div);

	const el = React.createElement(NotificationComponent, { title, message, type, duration })
	ReactDOM.render(el, div);

	setTimeout(() => {
		document.body.removeChild(div)
	}, duration + 500)
}

const NotificationComponent = ({ title = '', message, type = 'success', duration = 2000 }) => {
	const [open, setOpen] = useState(true)

	if (!open) return null

	return (
		<Snackbar
			open={open}
			autoHideDuration={duration}
			TransitionComponent={Fade}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			onClose={() => setOpen(false)}>
			<Alert severity={type}>
				{ title && title.length > 0 &&
				<AlertTitle>{ title }</AlertTitle>
				}
				{ message }
			</Alert>
		</Snackbar>
	)
}

export default Notification

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://4yrqcnip5jazffcd5dkfo3cyfe.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-b52y564f5beenhobwkqidea5wa",
    "aws_cognito_identity_pool_id": "us-east-1:35817af0-5af9-4689-a203-c2cbe1f1cda5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ISZIrLdbl",
    "aws_user_pools_web_client_id": "5nd27a9jped8cn2uldkkgjet6i",
    "oauth": {
        "domain": "jx6faw8m2uxo-staging.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000,https://ourdatax.org,https://www.ourdatax.org",
        "redirectSignOut": "http://localhost:3000,https://ourdatax.org,https://www.ourdatax.org",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "NAME",
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "datax-storage-4874b2ab41733-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;

import React from 'react'

import Hero from '../components/Hero'

import featureIcon1 from '../assets/stem.png'
import featureIcon2 from '../assets/datasearch.png'
import featureIcon3 from '../assets/story.png'

import '../css/IntroPage.css'

const IntroPage = ({ onClickLogin }) => {
  const featuredIntros = [
    {
      title: 'STEM Learning',
      icon: featureIcon1,
      info: 'Inspiring educators to integrate computing with STEM learning',
    },
    {
      title: 'Data Expeditions',
      icon: featureIcon2,
      info:
        'Engaging students in building scientific explanations based on real-world open science data',
    },
    {
      title: 'Data Stories',
      icon: featureIcon3,
      info:
        'Translating data analyses and creating data stories from dataset expeditions',
    },
  ]

  return (
    <div>
      <Hero onClickLogin={onClickLogin} />

      <div className='intro-title'>
        <h4 className='intro-text'>What does DataX bring for you?</h4>
      </div>

      <div className='intro-container'>
        <div className='ui three column grid'>
          {featuredIntros.map((feature, key) => {
            return (
              <div key={key} className='column intro-column'>
                <div className='ui fluid card feature-card'>
                  <div className='content'>
                    <div className='header feature-title'>
                      {feature.title}
                    </div>
                  </div>
                  <div className='image'>
                    <img src={feature.icon} alt={feature.title}></img>
                  </div>
                  <div className='content feature-desc'>
                    <div className='description feature-info'>
                      {feature.info}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div className='feature-btn-container'>
          <button className='ui button feature-btn' onClick={onClickLogin} >Sign up</button>
        </div>
      </div>
    </div>
  )
}

export default IntroPage

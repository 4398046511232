import React, { useEffect, useRef, useState } from 'react'
import { Storage } from 'aws-amplify'

import { translateCodap } from '../../xAPI/Codap'
import { saveToLRS } from '../../xAPI'

import Loading from '../ui/Loading'
import Notification from '../ui/Notification'
import Toolbar from '../dx/Toolbar'

const Codap = (props) => {
  const BASE_URL = 'https://codap.ourdatax.org/static/dg/en/cert/index.html'
  const [codapFile, setCodapFile] = useState()
  const [loading, setLoading] = useState(true)
  const [deleted, setDeleted] = useState(false)
  const codapRef = useRef()
  let autoSaveTimer

  const handleMessageReceived = (message) => {
    if (!message || !message.data || message.data.type === 'hello') {
      return
    }
    const childWindow = document.getElementById(props.id);
    if (message.source !== childWindow && message.source !== childWindow.contentWindow) {
      return
    }

    if (message.data.type === 'cfm::iframedClientConnected') {
      return setLoading(false)
    }
    if (message.data.type === 'codap::event') {
      // USE message.data.content.name instead
      // USE message.data.content.component for more info
      saveToLRS(props.user, translateCodap(message.data.content), props.project.id)
    }
    if (message.data.type === 'cfm::setDirty') {
      if (message.data.isDirty && props.isOwner) {
        autoSaveTimer && clearTimeout(autoSaveTimer)
        autoSaveTimer = setTimeout(() => {
          codapRef.current.contentWindow.postMessage({ 'type': 'cfm::getContent' }, '*')
        }, 5000)
      }
    }
    // TODO: capture edit title event
    if (message.data.type === 'cfm::getContent') {
      if (message.data.content && props.isOwner) {
        handleSaveFile(message.data.content, () => {
          codapRef.current.contentWindow.postMessage({ 'type': 'cfm::setDirty', 'isDirty': false }, '*')
          Notification({
            title: 'Success',
            message: 'Changes saved',
            type: 'success',
          })
        })
      }
    }
  }

  const isTouchEnabled = () => false//'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
  const isCodapActive = e => {
    const active = e && e.target && e.target.id === `helper-${props.id}`
    document.body.className = active ? 'codap-active' : ''

    const $components = document.getElementsByClassName('dx-component')
    Array.from($components).forEach($c => $c && $c.classList && $c.classList.remove('active'))

    const $el = document.getElementById(`helper-${props.id}`)
    if ($el && $el.parentNode && active) {
      $el.parentNode.classList.add('active')
    }

    if (active) {
      document.body.addEventListener('touchstart', isCodapActive)
    } else {
      document.body.removeEventListener('touchstart', isCodapActive)
    }
  }

  useEffect(() => {
    const { file } = props

    const getFile = async () => {
      const res = await Storage.get(file.key, {
        level: 'protected',
        identityId: file.key.split('/')[2],
      })

      window.addEventListener('message', handleMessageReceived)

      setCodapFile(res)
      setLoading(false)
    }

    setTimeout(() => {
      if (isTouchEnabled()) {
        document.getElementById(`helper-${props.id}`).removeEventListener('touchstart', isCodapActive)
        document.getElementById(`helper-${props.id}`).addEventListener('touchstart', isCodapActive)
      }
    }, 5000)

    getFile()

    return () => {
      window.removeEventListener('message', handleMessageReceived)
      if (isTouchEnabled() && document.getElementById(`helper-${props.id}`)) {
        document.getElementById(`helper-${props.id}`).removeEventListener('touchstart', isCodapActive)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSaveFile = async (content, callback) => {
    const { isOwner, file } = props
    if (!isOwner) {
      return
    }

    const res = await Storage.put(file.key, JSON.stringify(content), {
      level: 'protected',
      identityId: file.key.split('/')[2],
    })

    callback && callback(res)
  }

  if (deleted) return null

  return (
    <div className='dx-component' style={{textAlign: 'center', ...(props.standalone && { margin: 0 })}}>
      { loading && <Loading /> }
      { props.isOwner && !props.standalone &&
      <Toolbar
        id={props.id}
        type='data'
        project={props.project}
        index={props.index}
        length={props.length}
        expandEnabled
        handleMoveItem={props.handleMoveItem}
        onDeleted={() => setDeleted(true)} />
      }
      { !loading &&
        <iframe
          id={props.id}
          ref={codapRef}
          title='codapFileIframe'
          width='100vw'
          style={{height: props.standalone ? 'calc(100vh - 70px - 0px)' : '640px', minHeight: '640px'}}
          // height={props.standalone ? '1000px' : '640px'}
          src={`${BASE_URL}?url=${codapFile}`}
        ></iframe>
      }
      { !loading && isTouchEnabled() &&
      <div id={`helper-${props.id}`} className='overlay-helper' />
      }
    </div>
  )
}

export default Codap

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { AmplifyS3Image } from '@aws-amplify/ui-react/legacy'
import { Button, Card, Icon } from 'semantic-ui-react'

const ProjectFeatured = ({ projectId, projectFeatured, onClickFeatured }) => {
	const [featured, setFeatured] = useState(projectFeatured)

	if (!onClickFeatured && !projectFeatured) {
		return null
	}
	return (
		onClickFeatured ?
			<Button
				className={'btn-feature'}
				onClick={() => {
					onClickFeatured(projectId, !featured)
					setFeatured(!featured)
				}}
				><Icon name={`star${ featured ? '' : ' outline'}`} /></Button>
				:
			<Icon className={'btn-feature'} name={`star${ featured ? '' : ' outline'}`} />
	)
}

const ProjectCard = ({ project, onClickFeatured }) => (
	<Card className='item-card ui link' key={project.id}>
		<Link to={`/projects/${project.id}`} className='s-image'>
			<AmplifyS3Image imgKey={project.file.key} />
		</Link>
		<Link to={`/projects/${project.id}`}>
			<Card.Content>
				<Card.Header>
					<span className='card-header-text'>{project.name}</span>
				</Card.Header>
				<Card.Meta>
					{project.owner.name}
				</Card.Meta>
			</Card.Content>
		</Link>
		<ProjectFeatured
			projectId={project.id}
			projectFeatured={project.featured}
			onClickFeatured={onClickFeatured} />
	</Card>
)

export default ProjectCard
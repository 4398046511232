
import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { Button, CircularProgress, Grid, IconButton, Link, TextField, Typography } from '@material-ui/core'
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { Icon } from 'semantic-ui-react'

import GoogleIcon from './../icons/GoogleIcon'

import aws_exports from '../../aws-exports'

import './AuthForm.css'

class AuthForm extends React.Component {
  state = {
    formType: 'signIn',
    email: null,
    error: null,
    loading: false
  }

  formTitle = () => {
    switch (this.state.formType) {
      case 'signUp':
        return 'Create a new account'
      case 'signIn':
        return 'Sign in to your account'
      case 'resetPassword':
      case 'changePassword':
        return 'Reset your password'
      case 'confirmSignup':
        return 'Confirm Account'
      default:
        return 'Welcome'
    }
  }

  errorMessage = (err) => {
    if (err && err.message) {
      this.setState({ error: err.message })
    }
  }

  signUp = async (email, password, name) => {
    try {
      await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          email: email,
          name: name
        }
      })
      this.setState({ formType: 'confirmSignup', email: email, error: null })
    } catch (err) {
      this.errorMessage(err)
    }
  }

  signIn = async (email, password) => {
    try {
      this.setState({ loading: true })
      await Auth.signIn(email, password)
    } catch (err) {
      this.setState({ loading: false })
      this.errorMessage(err)
    }
  }

  resetPassword = async (email) => {
    try {
      await Auth.forgotPassword(email)
      this.setState({ formType: 'changePassword', email: email, error: null })
    } catch (err) {
      this.errorMessage(err)
    }
  }

  changePassword = async (code, password) => {
    try {
      await Auth.forgotPasswordSubmit(this.state.email, code, password)
      this.setState({ formType: 'signIn', email: null, error: null })
    } catch (err) {
      this.errorMessage(err)
    }
  }

  confirmSignup = async (code) => {
    try {
      await Auth.confirmSignUp(this.state.email, code)
      this.setState({ formType: 'signIn', email: null, error: null })
    } catch (err) {
      this.errorMessage(err)
    }
  }

  render() {
    const { loading, error, formType } = this.state
    const { open, onClose } = this.props

    return (
      <Dialog
        className='auth-form'
        open={open}
        onClose={(event, reason) => {
					if (reason === 'backdropClick') {
						onClose && onClose(event, reason)
					}
				}}
        maxWidth='xs'
        fullWidth>
        <DialogTitle disableTypography>
          <Typography variant='h6'>{this.formTitle()}</Typography>
          <IconButton
            className='dialog-close'
            onClick={onClose}
            aria-label='Close'>
            <Icon name='close' />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {formType === 'signUp' &&
            <SignUp
              signUp={this.signUp}
              updateForm={type => this.setState({ formType: type, error: null })} />
          }
          {formType === 'signIn' &&
            <SignIn
              loading={loading}
              signIn={this.signIn}
              updateForm={type => this.setState({ formType: type, error: null })} />
          }
          {formType === 'resetPassword' &&
            <ResetPassword
              resetPassword={this.resetPassword}
              updateForm={type => this.setState({ formType: type, error: null })} />
          }
          {formType === 'changePassword' &&
            <ChangePassword
              changePassword={this.changePassword} />
          }
          {formType === 'confirmSignup' &&
            <ConfirmSignup
              confirmSignup={this.confirmSignup} />
          }
          {error &&
            <Typography variant='subtitle1' color='error'>{error}</Typography>
          }
        </DialogContent>
      </Dialog>
    )
  }
}

const SignUp = (props) => {
  const [focused, setFocused] = useState()
  const [email, setEmail] = useState()
  const [validEmail, setValidEmail] = useState()
  const [password, setPassword] = useState()
  const [validPassword, setValidPassword] = useState()
  const [name, setName] = useState()

  return (
    <form>
      <GoogleButton />
      <OrWrap />
      <TextField
        label='Email address'
        type='email'
        variant='outlined'
        fullWidth
        style={styles.textField}
        defaultValue={email}
        error={email != null && !validEmail}
        onChange={e => {
          setEmail(e.target.value)
          setValidEmail(isEmail(e.target.value))
        }}
        required />
      <div style={{position: 'relative'}}>
      <TextField
        label='Password'
        type='password'
        variant='outlined'
        fullWidth
        style={styles.textField}
        defaultValue={password}
        error={validPassword === false}
        onChange={e => setPassword(e.target.value)}
        onFocus={ _ => setFocused(true) }
        onBlur={ _ => setFocused(false) }
        required />
      { focused &&
      <PasswordErrors
        className='float'
        password={password}
        isValidPassword={valid => setValidPassword(valid) } />
      }
      </div>
      <TextField
        label='Display Name'
        type='name'
        variant='outlined'
        fullWidth
        style={styles.textField}
        defaultValue={name}
        error={name != null && name.length <= 0}
        onChange={e => setName(e.target.value)}
        required />
      <Grid container justifyContent='flex-end' style={styles.textField}>
        <Button
          onClick={() => props.signUp(email, password, name)}
          disabled={!(validEmail && validPassword && name && name.length > 0)}
          variant='contained'
          primary="true">Create Account</Button>
      </Grid>
      <p
        style={styles.textField}>
        Already have an account?&nbsp;
        <Link
          onClick={() => { props.updateForm('signIn') }}>
          Sign In
        </Link>
      </p>
    </form>
  )
}

const SignIn = (props) => {
  const [email, setEmail] = useState()
  const [validEmail, setValidEmail] = useState()
  const [password, setPassword] = useState()

  return (
    <form onSubmit={e => {
      e.preventDefault()
      props.signIn(email, password)
    }}>
      <GoogleButton />
      <OrWrap />
      <TextField
        label='Email address'
        type='email'
        variant='outlined'
        fullWidth
        style={styles.textField}
        defaultValue={email}
        error={email != null && !validEmail}
        onChange={e => {
          setEmail(e.target.value)
          setValidEmail(isEmail(e.target.value))
        }}
        required />
      <TextField
        label='Password'
        type='password'
        variant='outlined'
        fullWidth
        style={styles.textField}
        defaultValue={password}
        onChange={e => setPassword(e.target.value)}
        required />
      <Link
        onClick={() => { props.updateForm('resetPassword') }}>
        Forgot password?
      </Link>
      <Grid container justifyContent='flex-end'>
        <Button
          type='submit'
          disabled={props.loading || !validEmail || password == null || password.length === 0}
          variant='contained'
          primary="true">{props.loading ? <CircularProgress size={18} /> : 'Sign in'}</Button>
      </Grid>
      <p
        style={styles.textField}>
        Don't have an account?&nbsp;
        <Link
          onClick={() => { props.updateForm('signUp') }}>
          Create an account
        </Link>
      </p>
    </form>
  )
}

const ResetPassword = (props) => {
  const [email, setEmail] = useState()
  const [validEmail, setValidEmail] = useState()

  return (
    <form>
      <TextField
        label='Email address'
        type='email'
        variant='outlined'
        fullWidth
        style={styles.textField}
        defaultValue={email}
        error={email != null && !validEmail}
        onChange={e => {
          setEmail(e.target.value)
          setValidEmail(isEmail(e.target.value))
        }}
        required />
      <Grid container justifyContent='flex-end' style={styles.textField}>
        <Button
          onClick={() => props.resetPassword(email)}
          disabled={!validEmail}
          variant='contained'
          primary="true">Reset Password</Button>
      </Grid>
      <p
        style={styles.textField}>
        Don't have an account?&nbsp;
        <Link
          onClick={() => { props.updateForm('signUp') }}>
          Create an account
        </Link>
      </p>
    </form>
  )
}

const ChangePassword = (props) => {
  const [focused, setFocused] = useState()
  const [code, setCode] = useState()
  const [password, setPassword] = useState('')
  const [validPassword, setValidPassword] = useState()

  return (
    <form>
      <TextField
        label='Verification code'
        type='text'
        variant='outlined'
        fullWidth
        style={styles.textField}
        defaultValue={code}
        onChange={e => setCode(e.target.value)}
        required />
      <TextField
        label='Password'
        type='password'
        variant='outlined'
        fullWidth
        style={styles.textField}
        error={validPassword === false}
        defaultValue={password}
        onChange={e => setPassword(e.target.value)}
        onFocus={ _ => setFocused(true) }
        onBlur={ _ => setFocused(false) }
        required />
      { focused &&
      <PasswordErrors
        password={password}
        isValidPassword={valid => setValidPassword(valid) } />
      }
      <Grid container justifyContent='flex-end' style={styles.textField}>
        <Button
          onClick={() => props.changePassword(code, password)}
          variant='contained'
          primary="true">Change Password</Button>
      </Grid>
    </form>
  )
}

const ConfirmSignup = (props) => {
  const [code, setCode] = useState()

  return (
    <form>
      <TextField
        label='Verification code'
        type='text'
        variant='outlined'
        fullWidth
        style={styles.textField}
        defaultValue={code}
        onChange={e => setCode(e.target.value)}
        required />
      <Grid container justifyContent='flex-end' style={styles.textField}>
        <Button
          onClick={() => props.confirmSignup(code)}
          variant='contained'
          primary="true">Confirm Signup</Button>
      </Grid>
    </form>
  )
}


const PasswordErrors = (props) => {
  const isValidPassword = password => {
    let requirements = aws_exports.aws_cognito_password_protection_settings
      if (!requirements) {
        requirements = {
          passwordPolicyMinLength: 8,
          passwordPolicyCharacters: [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
          ]
        }
      }

      let errorMessages = {
        minLength: `At least ${requirements.passwordPolicyMinLength} characters`,
        lowercase: 'Lowercase characters',
        uppercase: 'Uppercase characters',
        symbols: 'Symbols',
        numbers: 'Numbers'
      }
      let errors = []
      if (requirements.passwordPolicyMinLength) {
        errors.push({
          message: errorMessages.minLength,
          error: password.length < requirements.passwordPolicyMinLength
        })
      }
      if (requirements.passwordPolicyCharacters) {
        for (let i in requirements.passwordPolicyCharacters) {
          const rule = requirements.passwordPolicyCharacters[i]
          switch (rule) {
            case 'REQUIRES_LOWERCASE':
              errors.push({
                message: errorMessages.lowercase,
                error: !password || password.toUpperCase() === password ? true : false
              })
              break;
            case 'REQUIRES_UPPERCASE':
              errors.push({
                message: errorMessages.uppercase,
                error: !password || password.toLowerCase() === password ? true : false
              })
              break;
            case 'REQUIRES_SYMBOLS':
              errors.push({
                message: errorMessages.symbols,
                // eslint-disable-next-line no-useless-escape
                error: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password) !== true
              })
              break;
            case 'REQUIRES_NUMBERS':
              errors.push({
                message: errorMessages.numbers,
                error: /\d/.test(password) !== true
              })
              break;
            default:
              console.log('Unknown')
          }
        }
      }
      return errors
  }

  let countErrors = 0
  let errors = props.password && isValidPassword(props.password)

  for (let i in errors) {
    if (errors[i].error === true) {
      countErrors++
    }
  }

  props.isValidPassword && props.isValidPassword(countErrors === 0)

  return (countErrors > 0 && (
    <div className={'password-errors' + (props.className ? ` ${props.className}` : '')}>
      { errors.map((error, i) => (
        <Typography key={i} variant='subtitle1'>{ error.error ? '❌' : '✅' } { error.message }</Typography>
      ))}
    </div>
  ))
}

const isEmail = email => {
  return /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(email)
}

function GoogleButton() {
  return (
    <Button
      style={{
        padding: '10px',
        border: '1px solid #999',
        borderRadius: '100px'
      }}
      fullWidth
      onClick={() => Auth.federatedSignIn({ provider: 'Google' })}
    >
      <GoogleIcon />
      <span>&nbsp;&nbsp;Sign in with Google</span>
    </Button>
  )
}

function OrWrap() {
  return (
    <div className='OrWrap'>
      <div className='centre-line'>
        <span>OR</span>
      </div>
    </div>
  )
}

const styles = {
  textField: {
    margin: '0.5em 0'
  }
}

export default AuthForm

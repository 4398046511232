import React from 'react'
import { Link } from 'react-router-dom'

const NotFoundPage = ({ title }) => {
	return (
		<div style={{ textAlign: 'center', padding: 50 }}>
			<h1>{ title || 'Page'} Not Found</h1>
			<Link to={'/'}>Go Home</Link>
		</div>
	)
}

export default NotFoundPage

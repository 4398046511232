// Sample xAPI schema
// * https://activitystrea.ms/head/activity-schema.html#verbs
// * https://profiles.adlnet.gov/profile/b4b035ea-5a25-45b0-bef0-5875f669e8cc
// * http://id.tincanapi.com/verb/downloaded

const CONSTANTS = {
	'endpoint': 'https://ourdatax.lrs.io/xapi',
	'auth': {
		'basic': 'd286OEhOVHE1M2lib2RxazNzclozVFM=',
		// 'username': 'USERNAME',
		// 'password': 'PASSWORD'
	}
}

const buildStatement = (user, statement, project) => {
	if (!statement || !statement.verb || !statement.object) return
	if (project) {
		if (project.studio) {
			statement.object.id = `https://ourdatax.org/studios/${project.studio}`
		} else {
			statement.object.id = `https://ourdatax.org/projects/${project}`
		}
	}

	return {
		// https://github.com/adlnet/xAPI-Spec/blob/master/xAPI-Data.md#actor
		'actor': {
			'objectType': 'Agent',
			'name': user.name,
			// https://github.com/adlnet/xAPI-Spec/blob/master/xAPI-Data.md#agentaccount
			'account': {
				'name': user.sub,
				'homePage': 'https://ourdatax.org'
			}
		},
		// https://github.com/adlnet/xAPI-Spec/blob/master/xAPI-Data.md#243-verb
		'verb': statement.verb,
		// https://github.com/adlnet/xAPI-Spec/blob/master/xAPI-Data.md#object
		'object': Object.assign({}, statement.object, { 'objectType': 'Activity' }),
		'version': '1.0'
	}
}

export const saveToLRS = (user, statement, project) => {
	const stmt = buildStatement(user, statement, project)

	if (!stmt) {
		return
	}

	const xhr = new XMLHttpRequest()
	xhr.open('POST', `${CONSTANTS.endpoint}/statements`)

	xhr.setRequestHeader('Accept', 'application/json')
	xhr.setRequestHeader('Content-Type', 'application/json')
	if (CONSTANTS.auth.basic) {
		xhr.setRequestHeader('Authorization', `Basic ${CONSTANTS.auth.basic}`)
	} else if (CONSTANTS.auth.username && CONSTANTS.auth.password) {
		xhr.setRequestHeader('Authorization', 'Basic ' + btoa(`${CONSTANTS.auth.username}:${CONSTANTS.auth.password}`))
	}

	xhr.onload = () => {}//console.log(xhr.responseText)

	xhr.send(JSON.stringify(stmt))
}

// UNUSED BUT KEEP JUST IN CASE
// eslint-disable-next-line no-unused-vars
const parsePlugin = text => {
	if (text && text.indexOf('addGame: ' === 0)) {
		const parts = text.substring(8)
		if (parts && parts.length > 0) {
			const json = parseJSON(parts)
			console.log(json && json.name ? json.name : '???')
		}
	}
	return null
}

const parseJSON = json => {
	// https://stackoverflow.com/a/39050609
	let clean = json
		// Replace ":" with "@colon@" if it's between double-quotes
		.replace(/:\s*"([^"]*)"/g, (match, p1) => {
			return ': "' + p1.replace(/:/g, '@colon@') + '"'
		})
		// Replace ":" with "@colon@" if it's between single-quotes
		.replace(/:\s*'([^']*)'/g, (match, p1) => {
			return ': "' + p1.replace(/:/g, '@colon@') + '"'
		})
		// Add double-quotes around any tokens before the remaining ":"
		.replace(/(['"])?([a-z0-9A-Z_]+)(['"])?\s*:/g, '"$2": ')
		// Turn "@colon@" back into ":"
		.replace(/@colon@/g, ':')

	return JSON.parse(clean)
}

import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { API, graphqlOperation } from 'aws-amplify'

import { getProject } from '../../graphql/queries'

import { UserContext } from '../../App'

import Codap from './Codap'

import Loading from '../ui/Loading'
import NotFoundPage from '../../pages/NotFoundPage'

import '../../css/ProjectPage.css'

const BlockPage = ({ projectId, componentId }) => {
  const [block, setBlock] = useState()
  const [owner, setOwner] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    handleGetProject()
    // HACKY WAY
    // Ideally would be handled in router
    const footers = document.getElementsByTagName('footer')
    footers[0].style.display = 'none'
    return () => {
      footers[0].style.display = 'block'
    }
    // eslint-disable-next-line
  }, [])

  const handleGetProject = async () => {
    const result = await API.graphql(graphqlOperation(getProject, { id: projectId }))
    if (!(result && result.data && result.data.getProject)) {
      setLoading(false)
      return console.error('Could not load project')
    }
    const project = result.data.getProject
    const block = ((project.components && project.components.items) || []).find(b => b.id === componentId)
    setBlock(block)
    setOwner(project.owner)
    setLoading(false)
  }

  if (loading) {
    return <Loading fullscreen />
  }
  if (!block) {
    return <NotFoundPage title='Component' />
  }
  if (block.type !== 'CODAP') {
    return <h1>Component type not supported</h1>
  }
  const tags = [...document.getElementsByTagName('main')]
  tags.forEach(t => t.style.padding = 0)

  return (
    <UserContext.Consumer>
      {({ userAttributes }) => {
        const isProjectOwner = userAttributes && userAttributes.sub === owner.id
        return (
          <Codap
            {...block}
            standalone
            isOwner={isProjectOwner}
            user={userAttributes}
            key={block.id} />
        )
      }}
    </UserContext.Consumer>
  )
}

export default withRouter(BlockPage)

import React from 'react'
import { Button, Icon } from 'semantic-ui-react'

const BlockIcon = ({ title, icon, disabled, loading, onClick }) => (
	<Button
		animated='vertical'
		size='huge'
		onClick={ onClick }
		disabled={disabled || loading}
		loading={loading}>
		<Button.Content hidden>{ title }</Button.Content>
		<Button.Content visible>
			<Icon name={ icon } />
		</Button.Content>
	</Button>
)

export default BlockIcon
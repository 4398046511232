import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Amplify from "aws-amplify";
import aws_exports from "./aws-exports";
import * as serviceWorker from "./serviceWorker";

// TODO:
// Handle this differently. Maybe with .env but maybe just parse aws-exports
// https://watilde.medium.com/how-to-support-multiple-redirect-urls-of-auth-with-amplify-7bffe5798047
if (aws_exports && aws_exports.oauth) {
	let { redirectSignIn, redirectSignOut } = aws_exports.oauth
	// redirectSignIn = redirectSignIn.split(',')
	// redirectSignOut = redirectSignOut.split(',')
	if (window.location.origin.indexOf('localhost')) {
		redirectSignIn = window.location.origin
		redirectSignOut = window.location.origin
	} else {
		redirectSignIn = 'https://2022.ourdatax.org'
		redirectSignOut = 'https://2022.ourdatax.org'
	}
	aws_exports.oauth.redirectSignIn = redirectSignIn
	aws_exports.oauth.redirectSignOut = redirectSignOut
} else {
	console.error('Auth not configured correctly')
}

Amplify.configure(aws_exports);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import React,
	{ useState, useEffect, useRef } from 'react'

import './TextLoop.css'

const TextLoop = ({ children = [], interval = 2000 }) => {
	const [current, setCurrent] = useState(0)
	const els = useRef({})

	useEffect(() => {
		const timeout = setInterval(_ => {
			setCurrent(current + 1 >= children.length ? 0 : current + 1)
		}, interval)
		return () => clearInterval(timeout)
	})

	const getWidth = () => els.current[current] ?
		els.current[current].getBoundingClientRect().width :
		"auto"

	return (
		<span className={'text-loop'} style={{animationDelay: `${interval}ms`}}>
			<div
				className={'text-loop-wrapper'}
				style={{width: getWidth()}}>
			{ children.map((e, i) => (
				<span
					key={i}
					ref={el => els.current[i] = el}
					className={i === current ? 'is-visible' : 'is-hidden'}>{ e }</span>
				))
			}
			</div>
		</span>
	)
}

export default TextLoop

import React, { useState } from 'react'
import { API, Auth, graphqlOperation } from 'aws-amplify'

import { updateProjectComponent } from '../../graphql/mutations'

import { Codap, Draw, Text } from '../blocks'
import { CodapIcon, DrawIcon, TextIcon } from '../blocks/icons'

import { translateDataX } from '../../xAPI/DataX'
import { saveToLRS } from '../../xAPI'

const ProjectBlocks = (props) => {
	const [state, setState] = useState(0)

	let blocks = props.blocks && props.blocks.items ? props.blocks.items : []
	blocks = blocks.filter(b => b.state !== 0)

	const sortBlocks = () => {
		blocks = blocks.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
		blocks = blocks.sort((a, b) => (null != a.index ? a.index : 99) - (null != b.index ? b.index : 99))
	}
	sortBlocks()

	const moveBlock = (from, to) => {
		const list = [...blocks]
		const el = list.splice(from, 1)
		list.splice(to, 0, el[0])
		return list
	}

	const codapComponents = blocks.filter(b => b.type === 'CODAP')
	const drawComponents = blocks.filter(b => b.type === 'DRAW')
	const textComponents = blocks.filter(b => b.type === 'TEXT')

	const handleMoveItem = async (from, to) => {
		const moved = moveBlock(from, to)
		for (const i in moved) {
			await API.graphql(
				graphqlOperation(updateProjectComponent, {
					input: {
						id: moved[i].id,
						index: i
					}
				})
			)
			moved[i].index = i
		}
		blocks = moved
		setState(state + 1)
		logMoveComponent(blocks[to])
	}

	const logMoveComponent = async (component) => {
		const reorderComponent = translateDataX('reorder', 'component', component.id)
		const user = await Auth.currentAuthenticatedUser()
		saveToLRS(user.attributes, reorderComponent, component.project.id)
	}

	return (
		<div style={{ padding: '1em 0', margin: '0 -5em' }} key={state}>
			{ blocks.map((b, i) => {
				if (b.type === 'CODAP') {
					return (
						<Codap
							{...b}
							isOwner={props.isOwner}
							user={props.user}
							index={i}
							length={blocks.length}
							handleMoveItem={handleMoveItem}
							key={i} />
					)
				} else if (b.type === 'DRAW') {
					return (
						<Draw
							{...b}
							isOwner={props.isOwner}
							user={props.user}
							index={i}
							length={blocks.length}
							handleMoveItem={handleMoveItem}
							key={i} />
					)
				} else if (b.type === 'TEXT') {
					return (
						<Text
							{...b}
							isOwner={props.isOwner}
							user={props.user}
							index={i}
							length={blocks.length}
							handleMoveItem={handleMoveItem}
							key={i} />
					)
				}
				return <h1 key={i}>NOPE</h1>
			})}
			<br />
			{ props.isOwner &&
			<div style={{ textAlign: 'center', padding: '2em' }}>
				<h2>Add { blocks.length > 0 ? 'additional' : ''} components</h2>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<CodapIcon {...props} disabled={ codapComponents && codapComponents.length >= 5 } />
					<DrawIcon {...props} disabled={ drawComponents && drawComponents.length >= 5 } />
					<TextIcon {...props} disabled={ textComponents && textComponents.length >= 5 } />
				</div>
			</div>
			}
		</div>
	)
}

export default ProjectBlocks
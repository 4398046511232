import React, { useState } from 'react'
import { API, Auth, Storage, graphqlOperation } from 'aws-amplify'

import { createProjectComponent } from '../../../graphql/mutations'
import aws_exports from '../../../aws-exports'

import BlockIcon from './BlockIcon'
import CodapDialog from '../dialog/CodapDialog'

const DEFAULT_CODAP = {
	name: 'new',
	file: {
		name: null || 'Untitled Document',
		guid: 1,
		components: [],
		contexts: [],
		globalValues: [],
		appName: 'DG',
		appVersion: '2.0',
		appBuildNum: '0496',
		lang: 'en',
		metadata: {},
	}
}

const CodapIcon = (props) => {
	const [loading, setLoading] = useState(false)
	const [availableBlocks, setAvailableBlocks] = useState()

	const filterAvailableCodap = () =>
		(props.blocks && props.blocks.items ? props.blocks.items : []).filter(b => b.type === 'CODAP' && b.state !== 0)

	const onClose = () => {
		setAvailableBlocks(null)
	}

	const handleOnClick = async (option) => {
		let codap = DEFAULT_CODAP
		if (!option || !option.codapSelected) {
			const codapBlocks = filterAvailableCodap()
			if (codapBlocks && codapBlocks.length > 0) {
				return setAvailableBlocks(codapBlocks)
			}
		} else if (option && option.codapSelected !== 'new' && option.codapSelected.file) {
			const res = await Storage.get(option.codapSelected.file.key, {
				level: 'protected',
				identityId: option.codapSelected.file.key.split('/')[2],
				download: true
			})
			if (res && res.Body) {
				try {
					const text = await res.Body.text()
					codap.file = JSON.parse(text)
				} catch(e) {}
			}
		}

		const { identityId } = await Auth.currentCredentials()
		const { project } = props

		if (!project || !project.id) {
			return console.error('Did not receive project ID')
		}

		setLoading(true)
		const uploadedFile = await Storage.put(
			`/public/${identityId}/${project.id}-${Date.now()}.codap`,
			JSON.stringify(codap.file),
			{ level: 'protected' }
		)
		if (!uploadedFile || !uploadedFile.key) {
			setLoading(false)
			return console.error('Could not upload .codap file')
		}

		const result = await API.graphql(graphqlOperation(createProjectComponent, {
			input: {
				type: 'CODAP',
				file: {
					key: uploadedFile.key,
					bucket: aws_exports.aws_user_files_s3_bucket,
					region: aws_exports.aws_project_region,
				},
				projectID: project.id
			}
		}))

		props.onProjectComponentCreated && props.onProjectComponentCreated(result)

		setLoading(false)
	}

	return (
		<>
			<BlockIcon title='Data' icon='table' onClick={() => handleOnClick()} disabled={ props.disabled } loading={ loading } />
			{ availableBlocks && availableBlocks.length > 0 &&
			<CodapDialog
				options={ availableBlocks }
				onClose={ onClose }
				onConfirm={ handleOnClick } />
			}
		</>
	)
}

export default CodapIcon
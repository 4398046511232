import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import logo from '../assets/logo.png'
import '../css/Footer.css'

import pkg from '../../package.json'

class Footer extends React.Component {
  render() {
    return (
      <footer className='background'>
        <div className='ui secondary menu'>
          <Link to='/' className='item'>
            <img className='data-logo' src={logo} alt='logo'></img>
            <p className='logo-text'>DataX </p>
          </Link>
          <div className='right menu'>
            <a
              className='item footer-icon'
              target='_blank'
              rel='noopener noreferrer'
              href='https://learningfutures.github.io/projects/datax'>
              <i className='globe icon'></i>
            </a>
          </div>
        </div>
        <p className='nav-text copyright-text'>
          &#9400; DataX 2023 All Rights Reserved.
        </p>
        <p className='nav-text copyright-text'>v{pkg.version}</p>
      </footer>
    )
  }
}

export default withRouter(Footer)

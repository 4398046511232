import React from 'react'
import { withRouter } from 'react-router-dom'
import { Storage, API, Auth, graphqlOperation } from 'aws-amplify'

import { Button, CircularProgress, TextField, Select, MenuItem, InputLabel } from '@material-ui/core'
import { Dialog, DialogContent, DialogActions, FormControl } from '@material-ui/core'

import { DialogHeader } from '../ui/Dialog'
import ImagePicker from '../ui/ImagePicker'
import Notification from '../ui/Notification'

import { listUserStudios } from '../../graphql/queries'
import { createProject, updateProject } from '../../graphql/mutations'
import { translateDataX } from '../../xAPI/DataX'
import { saveToLRS } from '../../xAPI'
import aws_exports from '../../aws-exports'

import './../../css/v2/Dialog.css'

const DEFAULT_IMAGE = '/public/default/codapmini-1.1bf4b74a.png'

class ProjectDialog extends React.Component {
	state = {
		project: this.props.project,
		projectName: (this.props && this.props.project && this.props.project.name) || null,
		projectDescription: (this.props && this.props.project && this.props.project.description) || null,
		projectImage: null,
		projectImagePreview: (this.props && this.props.project && this.props.project.projectImagePreview) || null,
		saving: false,
		loadingStudios: false,
		selectedStudio: (this.props && this.props.project && this.props.project.studioID) || this.props.studioId || null
	}

	uploadImage = async () => {
		const { identityId } = await Auth.currentCredentials()
		const { projectImage } = this.state

		// IMAGE
		let file = {
			key: DEFAULT_IMAGE,
			bucket: aws_exports.aws_user_files_s3_bucket,
			region: aws_exports.aws_project_region,
		}
		if (projectImage && projectImage.name) {
			const uploadedFile = await Storage.put(
				`/public/${identityId}/${Date.now()}-${projectImage.name}`,
				projectImage,
				{ contentType: projectImage.type }
			)
			if (uploadedFile && uploadedFile.key) {
				file.key = uploadedFile.key
			}
		}

		return file
	}

	getStudios = async () => {
		const user = await Auth.currentAuthenticatedUser()
		const res = await API.graphql(graphqlOperation(listUserStudios, {
			filter: {
				userID: { eq: user.attributes.sub }
			},
		}))
		const userStudios = res.data.listUserStudios ? res.data.listUserStudios.items : []
		const studios = userStudios.map(us => us.studio)
		return studios
	}

	// combine update and save
	updateProject = async () => {
		this.setState({ saving: true })
		const { project, projectName, projectDescription, selectedStudio } = this.state

		try {
			const projectInput = {
				id: project.id,
				name: projectName,
				description: projectDescription
			}
			const file = await this.uploadImage()
			if (file && file.key !== DEFAULT_IMAGE) {
				projectInput.file = file
			}
			if (selectedStudio) {
				projectInput.studioID = selectedStudio
			}

			const result = await API.graphql(
				graphqlOperation(updateProject, { input: projectInput })
			)
			const user = await Auth.currentAuthenticatedUser()
			saveToLRS(user.attributes, translateDataX('edit', 'project'), project.id)
			Notification({
				title: 'Success',
				message: 'Project successfully updated!',
				type: 'success',
				duration: 2000,
			})
			this.setState({ saving: false })
			this.props.onClose && this.props.onClose(result.data.updateProject)
		} catch (err) {
			this.setState({ saving: false })
			console.error(`Failed to update project [${project.id}]`, err)
		}
	}

	saveProject = async () => {
		this.setState({ saving: true })

		const { projectName, projectDescription, projectImage, selectedStudio } = this.state

		try {
			const { identityId } = await Auth.currentCredentials()

			// IMAGE
			let file = {
				key: '/public/default/codapmini-1.1bf4b74a.png',
				bucket: aws_exports.aws_user_files_s3_bucket,
				region: aws_exports.aws_project_region,
			}
			if (projectImage && projectImage.name) {
				const uploadedFile = await Storage.put(
					`/public/${identityId}/${Date.now()}-${projectImage.name}`,
					projectImage,
					{ contentType: projectImage.type }
				)
				if (uploadedFile && uploadedFile.key) {
					file.key = uploadedFile.key
				}
			}

			// SAVE PROJECT
			const user = await Auth.currentAuthenticatedUser()
			const projectInput = {
				name: projectName,
				description: projectDescription || '',
				file: file,
				userID: user.attributes.sub,
				type: 'Project'
			}
			if (this.props.studioId || selectedStudio) {
				projectInput.studioID = this.props.studioId || selectedStudio
			}
			const result = await API.graphql(graphqlOperation(createProject, { input: projectInput }))
			saveToLRS(user.attributes, translateDataX('create', 'project'), result.data.createProject.id)
			Notification({
				title: 'Success',
				message: 'Project successfully created!',
				type: 'success',
			})
			this.setState({ saving: false })
			this.props.history.push(`/projects/${result.data.createProject.id}`)
		} catch (err) {
			this.setState({ saving: false })
			console.error('Error saving project', err)
		}
	}

	onClose = (e) => {
		e.preventDefault()

		const {
			projectName,
			projectDescription,
			projectImage
		} = this.state

		if ((!projectName && !projectDescription && !projectImage) ||
			window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
			this.props.onClose && this.props.onClose()
		}
	}

	componentDidUpdate() {
		const {
			projectName,
			projectDescription,
			projectImage
		} = this.state

		if (projectName || projectDescription || projectImage) {
			window.onbeforeunload = () => true
		} else {
			window.onbeforeunload = undefined
		}
	}

	async componentDidMount() {
		let imageURL
		if (this.state.project && this.state.project.file) {
			imageURL = await Storage.get(this.state.project.file.key)
		}
		this.setState({ loadingStudios: true })
		const studios = await this.getStudios()
		this.setState({ projectImagePreview: imageURL, studios: studios, loadingStudios: false })
	}

	componentWillUnmount() {
		window.onbeforeunload = undefined
	}

	render() {
		const {
			project,
			projectName,
			projectDescription,
			projectImage,
			projectImagePreview,
			studios,
			selectedStudio,
			saving,
			loadingStudios
		} = this.state

		console.log(selectedStudio, project)

		return (
			<Dialog
				open={true}
				className='create-studio'
				onClose={(event, reason) => {
					if (reason === 'backdropClick') {
						this.onClose(event, reason)
					}
				}}
				maxWidth='sm'
				fullWidth>
				<DialogHeader
					title={project ? 'Update Project' : 'Create New Project'}
					onClose={this.onClose} />
				<DialogContent>
					<ImagePicker
						label='Project Picture'
						preview={projectImagePreview}
						onChange={(image, preview) => this.setState({ projectImage: image, projectImagePreview: preview })} />
					{/* <FormControl variant="outlined" fullWidth>
						<InputLabel id="project-template">Template</InputLabel>
						<Select
							variant="outlined"
							labelId="project-template"
							defaultValue={(project && project.studioID) || this.props.studioId || ""}
							onChange={e => { this.setState({ selectedStudio: e.target.value === "" ? null : e.target.value }) }}>
							{ [{ id: '', name: 'Default' }, { id: 1, name: 'Starter' }].map(s => <MenuItem key={ s.id } value={ s.id }>{ s.name }</MenuItem> )}
						</Select>
					</FormControl> */}
					<TextField
						label='Project Name'
						type='name'
						variant='outlined'
						fullWidth
						style={styles.textField}
						defaultValue={projectName}
						error={projectName != null && projectName.length <= 0}
						onChange={e => { this.setState({ projectName: e.target.value }) }}
						required />
					<TextField
						label='Description'
						type='name'
						variant='outlined'
						fullWidth
						style={styles.textField}
						defaultValue={projectDescription}
						onChange={e => { this.setState({ projectDescription: e.target.value }) }} />
					{ loadingStudios &&
					<CircularProgress size={18} />
					}
					{ studios && studios.length > 0 &&
					<FormControl variant="outlined" fullWidth>
						<InputLabel id="project-studio">Studio</InputLabel>
						<Select
							variant="outlined"
							labelId="project-studio"
							disabled={this.props.studioId ? true : false}
							defaultValue={(project && project.studioID) || this.props.studioId || ""}
							onChange={e => { this.setState({ selectedStudio: e.target.value === "" ? null : e.target.value }) }}>
							<MenuItem value="">None</MenuItem>
							{ studios.map(s => <MenuItem key={ s.id } value={ s.id }>{ s.name }</MenuItem> )}
						</Select>
					</FormControl>
					}
				</DialogContent>
				<DialogActions>
					{project ?
						<Button
							autoFocus
							disabled={(!projectImage && projectName === project.name && projectDescription === project.description && selectedStudio === project.studioID) || saving}
							onClick={this.updateProject}>{saving ? <CircularProgress size={18} /> : 'Update Project'}</Button>
						:
						<Button
							autoFocus
							disabled={!projectName || saving}
							onClick={this.saveProject}
							primary="true">{saving ? <CircularProgress size={18} /> : 'Create Project'}</Button>
					}
				</DialogActions>
			</Dialog>
		)
	}
}

const styles = {
	textField: {
		margin: '0.5em 0'
	}
}

export default withRouter(ProjectDialog)


import { useEffect } from 'react'
import { CircularProgress } from '@material-ui/core'

import './Loading.css'

const Loading = (props) => {
	useEffect(() => {
		document.body.style.overflow = 'hidden'
		return () => document.body.style.overflow = null
	})

	return (
		props && props.fullscreen ?
			<div className='dx-ui-loading'>
				<CircularProgress />
			</div>
			:
			<CircularProgress />
	)
}

export default Loading
import React from 'react'
import { IconButton, Typography } from '@material-ui/core'
import { DialogTitle } from '@material-ui/core'
import { Icon } from 'semantic-ui-react'

const DialogHeader = (props) => {
	return (
		<DialogTitle disableTypography>
			<Typography variant='h6'>{props.title}</Typography>
			<IconButton
				className='dialog-close'
				onClick={props.onClose}
				aria-label='Close'>
				<Icon name='close' />
			</IconButton>
		</DialogTitle>
	)
}

export {
	DialogHeader
}
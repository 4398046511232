import React from 'react'
import { Storage, Auth, API, graphqlOperation } from 'aws-amplify'
import { Statistic } from 'semantic-ui-react'

import { getUser } from '../graphql/queries'
import ProfileProjects from '../components/ProfileProjects'
import ProfileStudios from '../components/ProfileStudios'

import '../css/ProfilePage.css'

class ProfilePage extends React.Component {
	state = {
		email: this.props.userAttributes && this.props.userAttributes.email,
		emailDialog: false,
		verificationCode: "",
		verificationForm: false,
		orders: [],
		projectItems: [],
		studioItems: [],
		limitItems: 4,
		images: [],
		studioImages: [],
	}

	componentDidMount = async () => {
		if (this.props.userAttributes) {
			const { identityId } = await Auth.currentCredentials()
			this.setState({
				identityId: { identityId }
			})
			this.getPosts()
		}
	}

	getPosts = async () => {
		const input = {
			id: this.props.userAttributes.sub
		}
		const result = await API.graphql(graphqlOperation(getUser, input))

		let projectData = result.data.getUser.ownProject.items
		projectData = projectData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

		let studioData = result.data.getUser.studio.items
		studioData = studioData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

		this.setState({
			projectItems: projectData,
			studioItems: studioData
		})

		projectData.map(pro => {
			this.getImage(pro.file.key)
			return pro
		})

		studioData.map(pro => {
			return pro.studio.coverImage && this.getStudioImage(pro.studio.coverImage.key)
		})
	}

	getImage = async img => {
		let imageReturn = await Storage.get(img)
		this.setState(state => {
			const images = [...state.images, imageReturn]
			return { images }
		})
	}

	getStudioImage = async img => {
		let imageReturn = await Storage.get(img)
		this.setState(state => {
			const studioImages = [...state.studioImages, imageReturn]
			return { studioImages }
		})
	}

	onLoadMore = () => {
		this.setState({ limitItems: this.state.limitItems + 10 })
	}

	render() {
		const {
			projectItems,
			studioItems
		} = this.state
		const { user, userAttributes } = this.props

		return (
			userAttributes && (
				<>
					<div className="profile-page">
						<div className="profile-background">
							<div className="profile-container">
								<h1 className="profile-text profile-user">
									Hello, {user.displayName}!
								</h1>

								<Statistic.Group>
									<Statistic className="first-data">
										<Statistic.Label className="data-name">
											Projects
										</Statistic.Label>
										<Statistic.Value className="data-sta">
											{projectItems.length}
										</Statistic.Value>
									</Statistic>
									<Statistic>
										<Statistic.Label className="data-name">
											Studios
										</Statistic.Label>
										<Statistic.Value className="data-sta">
											{studioItems ? studioItems.length : 0}
										</Statistic.Value>
									</Statistic>
								</Statistic.Group>
							</div>
						</div>

						<div className="profile-content">
							<div className="profile-items">
								<h4 className="title-text">Studios</h4>
								<ProfileStudios
									studioItems={this.state.studioItems}
									imageLinks={this.state.studioImages}
									identityId={this.state.identityId}
								/>
								<h4 className="title-text">Projects</h4>
								<ProfileProjects
									projectItems={this.state.projectItems}
									imageLinks={this.state.images}
									identityId={this.state.identityId}
								/>
							</div>
						</div>
					</div>
				</>
			)
		)
	}
}

export default ProfilePage

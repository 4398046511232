// Activities and Verbs are defined in:
// * https://id.ourdatax.org/activities
// * https://id.ourdatax.org/activity/{{ activity }}
// * https://id.ourdatax.org/verbs
// * https://id.ourdatax.org/verb/{{ verb }}
import activities from './activities.json'
import verbs from './verbs.json'

export const translateDraw = (action, cmd) => {
	if (shouldIgnore(action)) return

	const dict = parseAction(action)
	const object = dict.object || getObject(cmd)

	if (['selected'].includes(action) && !object) return // ignore `selected` without selection
	return {
		verb: dict.verb,
		object: object
	}
}

const shouldIgnore = action => {
	const id = action.id || action
	// temporary ignore
	if (['ui:toggled_menu_opened', 'eraseline'].includes(id)) return true
	//
	if (['set_hovered_id', 'replace', 'set_editing_id', 'ui:toggled_disable_images', 'ui:toggled_is_loading'].includes(id)) return true
	if (id.indexOf('set_status:') >= 0) return true
	if (id.indexOf('session:') >= 0) return true
	if (id.indexOf('empty_canvas:') >= 0) return true
	if (id.indexOf('settings:') >= 0) return true
}

const parseAction = event => {
	if (!event) return
	const ids = (event.id || event).split(':')
	const key = verbs[ids[0]] ? ids[0] : (verbDictionary[ids[0]] ? verbDictionary[ids[0]] : 'unknown')

	if (componentDictionary[ids[0]]) {
		return {
			verb: verbs[key],
			object: activities[componentDictionary[ids[0]]]
		}
	}
	if (ids.length > 1 && ['tool'].includes(key)) {
		const object = activities[ids[1]] || activities[componentDictionary[ids[1]]] || activities['canvas']
		return { verb: verbs[key], object }
	}
	return { verb: verbs[key] }
}

const getObject = cmd => {
	if (!cmd) return
	const pageState = cmd.getPageState() || {}
	const selectedIds = pageState.selectedIds || []
	const shapes = cmd.getShapes() || []

	if (!selectedIds.length || !shapes.length) return activities['canvas']

	const selectedId = selectedIds[0]
	const shape = shapes.find(s => s.id === selectedId)

	const object = activities[shape.type] || activities[componentDictionary[shape.type]] || activities['canvas']
	if (shape && shape.id && object && object.definition) {
		object.definition.extensions = {
			'https://draw.ourdatax.org': {
				id: shape.id,
				point: shape.point,
				type: shape.type
			}
		}
	}
	return object
}

const verbDictionary = {
	//
	'selected_tool': 'tool',
	'create_draw': 'add',
	// GENERAL
	'selected': 'select',
	'translate': 'move',
	// 'delete': 'delete',
	// 'undo': 'undo',
	// 'redo': 'redo',
	'zoomed_camera': 'zoom',
	'zoomed_to_fit': 'zoom',
	// SETTINGS IN `style` MENU
	// 'style': 'style',
	// SETTINGS IN ... MENU
	// 'duplicate': 'duplicate',
	// 'rotate': 'rotate',
	// 'toggle': 'toggle',
	// CANVAS

	// 'arrow': '', // draw line or arrow
	'transform_single': 'add', // draw shape
	'patched_shapes': 'add', // text
	// 'edit': '', // after text
}

const componentDictionary = {
	'erase': 'eraser',
	'text': 'draw_text',
	//
	'create_draw': 'draw'
}

import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import Hero from '../components/Hero'
// import ProjectList from '../components/ProjectList'
import ProjectDialog from '../components/project/ProjectDialog'

import '../css/HomePage.css'
import '../css/StudioList.css'
import '../css/amplify/amplify.css'

const HomePage = props => {
	const [showProjectDialog, setShowProjectDialog] = useState(false)

	return (
		<>
			<Hero
				onClickNewProject={() => setShowProjectDialog(true)} />

			<div className='intro-title'>
				<h4 className='intro-text'>Explore current data investigations…</h4>
				<Link to='/profile' className='header-button'>
					<button className='ui button nav-btn'>
						View Profile
					</button>
				</Link>
				<br />
				<br />
			</div>
			{/* <ProjectList /> */}

			{ showProjectDialog &&
			<ProjectDialog onClose={() => setShowProjectDialog(false)} />
			}
		</>
	)
}

export default HomePage

// Activities and Verbs are defined in:
// * https://id.ourdatax.org/activities
// * https://id.ourdatax.org/activity/{{ activity }}
// * https://id.ourdatax.org/verbs
// * https://id.ourdatax.org/verb/{{ verb }}
import activities from './activities.json'
import verbs from './verbs.json'

export const translateDataX = (verb, activity, id) => {
	const object = activities[activity]
	if (id) {
		object.definition.extensions = {
			'https://ourdatax.org': { id: id }
		}
	}
	return {
		verb: verbs[verb],
		object: object
	}
}

import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Storage, API, Auth, graphqlOperation } from 'aws-amplify'
import { AmplifyS3Image } from '@aws-amplify/ui-react/legacy'
import { Card } from 'semantic-ui-react'

import Notification from '../components/ui/Notification'

import { UserContext } from '../App'
import ProjectDialog from './project/ProjectDialog'

import { createProject } from '../graphql/mutations'
import aws_exports from '../aws-exports'

import '../css/ProfileProject.css'
import '../css/v2/Card.css'

const initialState = {
  tags: ["Arts", "Web Dev", "Tech", "Crafts", "Entertainment"],
  options: [],
  nameProject: "",
  selectedTagsProject: [],
  optionsProject: [],
  addProjectDialog: false,
  descriptionProject: "",
  imagePreviewProject:
    "https://www.prestophoto.com/storage/static/landing/pdf-book-printing/upload-icon.png",
  imageProject: "",
  fileList: [],
  value: "",
  createNew: false,
  creatNewBtn: true,
  uploadFileBtn: false,
  image: [],
  codapFilePreview: "",
  codapFileResourse: {},
  limitItems: 4,

  percentUploaded: 0,
  isUploading: false
};

class ProfileProjects extends React.Component {
  state = {
    tags: ["Arts", "Web Dev", "Tech", "Crafts", "Entertainment"],
    options: [],
    nameProject: "",
    selectedTagsProject: [],
    optionsProject: [],
    addProjectDialog: false,
    descriptionProject: "",
    imagePreviewProject:
      "https://www.prestophoto.com/storage/static/landing/pdf-book-printing/upload-icon.png",
    imageProject: "",
    fileList: [],
    value: "",
    createNew: false,
    creatNewBtn: true,
    uploadFileBtn: false,
    image: [],
    codapFilePreview: "",
    codapFileResourse: {},
    limitItems: 4,

    percentUploaded: 0,
    isUploading: false
  };

  handleAddProject = async user => {
    try {
      this.setState({ addProjectDialog: false });
      const visibility = "public";
      const newCodapFile = {
        name: "Untitled Document",
        guid: 1,
        components: [],
        contexts: [],
        globalValues: [],
        appName: "DG",
        appVersion: "2.0",
        appBuildNum: "0496",
        lang: "en",
        metadata: {}
      };
      const { identityId } = await Auth.currentCredentials();
      var codapFileResourseName = "";
      var codapFileResourseFile = {};
      if (this.state.createNew === true) {
        codapFileResourseName = "new.codap";
        codapFileResourseFile = JSON.stringify(newCodapFile);
      } else {
        codapFileResourseName = this.state.codapFileResourse.name;
        codapFileResourseFile = this.state.codapFileResourse.file;
      }
      const filename = `/${visibility}/${identityId}/${Date.now()}-${
        this.state.imageProject.name
      }`;
      const codapFilename = `/${visibility}/${identityId}/${Date.now()}-${codapFileResourseName}`;
      const uploadedFile = await Storage.put(
        filename,
        this.state.imageProject.file,
        {
          contentType: this.state.imageProject.type,
          progressCallback: progress => {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            const percentUploaded = Math.round(
              (progress.loaded / progress.total) * 100
            );
            this.setState({ percentUploaded });
          }
        }
      );
      const uploadedCodapFile = await Storage.put(
        codapFilename,
        codapFileResourseFile,
        {
          level: "protected",
          // contentType: this.state.codapFileResourse.type,
          progressCallback: progress => {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            const percentUploaded = Math.round(
              (progress.loaded / progress.total) * 100
            );
            this.setState({ percentUploaded });
          }
        }
      );
      const file = {
        key: uploadedFile.key,
        bucket: aws_exports.aws_user_files_s3_bucket,
        region: aws_exports.aws_project_region
      };
      const codapFile = {
        key: uploadedCodapFile.key,
        bucket: aws_exports.aws_user_files_s3_bucket,
        region: aws_exports.aws_project_region
      };
      // const user = await Auth.currentAuthenticatedUser();
      const projectInput = {
        name: this.state.nameProject,
        description: this.state.descriptionProject,
        file: file,
        codapFile: codapFile,
        projectUserId: user.attributes.sub,
        projectOwnerId: user.attributes.sub,
        type: 'Project'
      };
      const result = await API.graphql(
        graphqlOperation(createProject, { input: projectInput })
      );
      Notification({
        title: "Success",
        message: "Project successfully created!",
        type: "success"
      });
      const newCreatedProjectUrl = "/projects/" + result.data.createProject.id;
      this.setState({ ...initialState }, () => {
        this.props.history.push(newCreatedProjectUrl);
      });
    } catch (err) {
      console.error("Error adding project", err);
    }
  };

  handleFilterTagsProject = query => {
    const optionsProject = this.state.tags
      .map(tag => ({ value: tag, label: tag }))
      .filter(tag => tag.label.toLowerCase().includes(query.toLowerCase()));
    this.setState({ optionsProject });
  };

  onLoadMore = () => {
    this.setState({
      limitItems: this.state.limitItems + 10
    });
  };

  render() {
    const {
      limitItems,
      addProjectDialog
    } = this.state;

    var projectData = this.props.projectItems;

    return (
      <UserContext.Consumer>
        {({ user }) => (
          <div>
            {projectData.length === 0 ? (
              <Card
                className="create-first-project ui link"
                onClick={() =>
                  this.setState({
                    ...initialState,
                    addProjectDialog: true
                  })
                }
              >
                <p className="add-first-project">+</p>
              </Card>
            ) : (
              <div>
                <Card.Group className="prof-project-list">
                  <Card
                    className="create-project ui link"
                    onClick={() =>
                      this.setState({
                        ...initialState,
                        addProjectDialog: true
                      })
                    }
                  >
                    <p className="add-project">+</p>
                  </Card>

                  {projectData.slice(0, limitItems).map((pro, index) => {
                    return (
                      <Card className="item-card ui link" key={pro.id}>
                        <Link to={`/projects/${pro.id}`} className="s-image">
                          <AmplifyS3Image
                            imgKey={pro.file.key}
                            wrapped
                            theme={{
                              photoImg: {
                                maxWidth: "100%"
                              }
                            }}
                          />
                        </Link>
                        <Link to={`/projects/${pro.id}`}>
                          <Card.Content>
                            <Card.Header>
                              <span className="card-header-text">
                                {pro.name}{" "}
                              </span>
                            </Card.Header>
                            <Card.Meta>
                              {pro.owner.name}
                            </Card.Meta>
                          </Card.Content>
                        </Link>
                      </Card>
                    );
                  })}
                </Card.Group>
                <div className="load-button">
                  {projectData.length !== 0 ? (
                    limitItems < projectData.length ? (
                      <button
                        className="ui button profile-button"
                        onClick={this.onLoadMore}
                      >
                        Load More
                      </button>
                    ) : (
                      <button className="ui button disabled profile-button">
                        No More Projects
                      </button>
                    )
                  ) : (
                    <div>
                      <p></p>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* Project Modal */}
            { addProjectDialog &&
            <ProjectDialog
              onClose={ () => { this.setState({ addProjectDialog: false }) } }
              />
            }
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default withRouter(ProfileProjects);

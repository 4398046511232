import React, { useEffect, useState } from 'react'
import { Storage } from 'aws-amplify'
import { Button, Form, Icon } from 'semantic-ui-react'
import ReactMarkdown from 'react-markdown'

import Loading from '../ui/Loading'
import Notification from '../ui/Notification'
import Toolbar from '../dx/Toolbar'

import { translateText } from '../../xAPI/Text'
import { saveToLRS } from '../../xAPI'

const Text = (props) => {
	const [loading, setLoading] = useState(true)
	const [editing, setEditing] = useState(false)
	const [deleted, setDeleted] = useState(false)
	const [saving, setSaving] = useState(false)
	const [autoSaveTimer, setAutoSaveTimer] = useState()

	const [mdContent, setMDContent] = useState('')

	useEffect(() => {
		const { file } = props

		const getFile = async () => {
			const res = await Storage.get(file.key, {
				level: 'protected',
				identityId: file.key.split('/')[2],
				download: true
			})
			if (res && res.Body) {
				res.Body.text()
					.then(text => {
						setMDContent(text)
						setLoading(false)
					})
					.catch(err => console.error('Unexpected error', err))
			}
		}
		getFile()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleSaveFile = async (content, autosave) => {
		const { isOwner, file } = props
		if (!isOwner) return

		setSaving(true)
		const res = await Storage.put(file.key, content, {
			level: 'protected',
			identityId: file.key.split('/')[2],
		})

		if (res && res.key) {
			Notification({
				title: 'Success',
				message: 'Changes saved',
				type: 'success',
			})
			!autosave && setEditing(false)
			logEvent('saved')
		}
		autoSaveTimer && clearTimeout(autoSaveTimer) && setAutoSaveTimer()
		setSaving(false)
	}

	const handleOnChange = e => {
		setMDContent(e.target.value)
		autoSaveTimer && clearTimeout(autoSaveTimer)
		setAutoSaveTimer(setTimeout(() => handleSaveFile(e.target.value, true), 5000))
	}

	const logEvent = (reason, cmd) => {
		saveToLRS(props.user, translateText(reason, props.id))
	}

	if (deleted) return null

	return (
		<div
			className='dx-component dx-text'
			style={{ width: 'calc(100% - 6em)' }}>
			{ props.isOwner &&
			<Toolbar
				id={props.id}
				type='text'
				project={props.project}
				index={props.index}
				length={props.length}
				handleMoveItem={props.handleMoveItem}
				onDeleted={() => setDeleted(true)} />
			}
			{ loading && <Loading /> }
			{ !loading && !editing &&
				<div
					onClick={() => setEditing(true)}>
					<ReactMarkdown children={mdContent} />
					{ (!mdContent || mdContent.length === 0) && props.isOwner &&
					<div style={{border: '1px dotted black', color: 'lightgray', padding: '1em' }}>
						Insert Text
					</div>
					}
				</div>
			}
			{ !loading && editing &&
				<Form>
					<Form.TextArea
						placeholder='Insert Text Here'
						value={mdContent}
						onChange={handleOnChange} />
					<Button
						onClick={() => handleSaveFile(mdContent)}
						variant='contained'
						disabled={saving}
						loading={saving}
						primary="true">Save Text</Button>
					<Button
						href='https://devhints.io/markdown'
						target='_blank'
						icon>
						<Icon name='help' />
					</Button>
				</Form>
			}
		</div>
	)
}

export default Text
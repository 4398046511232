/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      email
      name
      avatar {
        bucket
        region
        key
        __typename
      }
      registered
      ownProject {
        items {
          id
          name
          description
          complexity
          file {
            bucket
            region
            key
            __typename
          }
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          parentID
          parent {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          children {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          components {
            items {
              id
              type
              file {
                bucket
                region
                key
                __typename
              }
              projectID
              project {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              state
              index
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          type
          featured
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      studio {
        items {
          id
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          user {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        email
        name
        avatar {
          bucket
          region
          key
          __typename
        }
        registered
        ownProject {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        studio {
          items {
            id
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            user {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStudio = /* GraphQL */ `
  query GetStudio($id: ID!) {
    getStudio(id: $id) {
      id
      name
      description
      coverImage {
        bucket
        region
        key
        __typename
      }
      members {
        items {
          id
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          user {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      projects {
        items {
          id
          name
          description
          complexity
          file {
            bucket
            region
            key
            __typename
          }
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          parentID
          parent {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          children {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          components {
            items {
              id
              type
              file {
                bucket
                region
                key
                __typename
              }
              projectID
              project {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              state
              index
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          type
          featured
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      owner {
        id
        username
        email
        name
        avatar {
          bucket
          region
          key
          __typename
        }
        registered
        ownProject {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        studio {
          items {
            id
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            user {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      type
      createdAt
      updatedAt
      studioOwnerId
      __typename
    }
  }
`;
export const listStudios = /* GraphQL */ `
  query ListStudios(
    $filter: ModelStudioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudios(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        coverImage {
          bucket
          region
          key
          __typename
        }
        members {
          items {
            id
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            user {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        projects {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        owner {
          id
          username
          email
          name
          avatar {
            bucket
            region
            key
            __typename
          }
          registered
          ownProject {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          studio {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        type
        createdAt
        updatedAt
        studioOwnerId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      name
      description
      complexity
      file {
        bucket
        region
        key
        __typename
      }
      studioID
      studio {
        id
        name
        description
        coverImage {
          bucket
          region
          key
          __typename
        }
        members {
          items {
            id
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            user {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        projects {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        owner {
          id
          username
          email
          name
          avatar {
            bucket
            region
            key
            __typename
          }
          registered
          ownProject {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          studio {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        type
        createdAt
        updatedAt
        studioOwnerId
        __typename
      }
      userID
      owner {
        id
        username
        email
        name
        avatar {
          bucket
          region
          key
          __typename
        }
        registered
        ownProject {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        studio {
          items {
            id
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            user {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      parentID
      parent {
        id
        name
        description
        complexity
        file {
          bucket
          region
          key
          __typename
        }
        studioID
        studio {
          id
          name
          description
          coverImage {
            bucket
            region
            key
            __typename
          }
          members {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          projects {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          type
          createdAt
          updatedAt
          studioOwnerId
          __typename
        }
        userID
        owner {
          id
          username
          email
          name
          avatar {
            bucket
            region
            key
            __typename
          }
          registered
          ownProject {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          studio {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        parentID
        parent {
          id
          name
          description
          complexity
          file {
            bucket
            region
            key
            __typename
          }
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          parentID
          parent {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          children {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          components {
            items {
              id
              type
              file {
                bucket
                region
                key
                __typename
              }
              projectID
              project {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              state
              index
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          type
          featured
          createdAt
          updatedAt
          __typename
        }
        children {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        components {
          items {
            id
            type
            file {
              bucket
              region
              key
              __typename
            }
            projectID
            project {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            state
            index
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        featured
        createdAt
        updatedAt
        __typename
      }
      children {
        items {
          id
          name
          description
          complexity
          file {
            bucket
            region
            key
            __typename
          }
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          parentID
          parent {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          children {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          components {
            items {
              id
              type
              file {
                bucket
                region
                key
                __typename
              }
              projectID
              project {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              state
              index
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          type
          featured
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      components {
        items {
          id
          type
          file {
            bucket
            region
            key
            __typename
          }
          projectID
          project {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          state
          index
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      type
      featured
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        complexity
        file {
          bucket
          region
          key
          __typename
        }
        studioID
        studio {
          id
          name
          description
          coverImage {
            bucket
            region
            key
            __typename
          }
          members {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          projects {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          type
          createdAt
          updatedAt
          studioOwnerId
          __typename
        }
        userID
        owner {
          id
          username
          email
          name
          avatar {
            bucket
            region
            key
            __typename
          }
          registered
          ownProject {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          studio {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        parentID
        parent {
          id
          name
          description
          complexity
          file {
            bucket
            region
            key
            __typename
          }
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          parentID
          parent {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          children {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          components {
            items {
              id
              type
              file {
                bucket
                region
                key
                __typename
              }
              projectID
              project {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              state
              index
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          type
          featured
          createdAt
          updatedAt
          __typename
        }
        children {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        components {
          items {
            id
            type
            file {
              bucket
              region
              key
              __typename
            }
            projectID
            project {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            state
            index
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        featured
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProjectComponent = /* GraphQL */ `
  query GetProjectComponent($id: ID!) {
    getProjectComponent(id: $id) {
      id
      type
      file {
        bucket
        region
        key
        __typename
      }
      projectID
      project {
        id
        name
        description
        complexity
        file {
          bucket
          region
          key
          __typename
        }
        studioID
        studio {
          id
          name
          description
          coverImage {
            bucket
            region
            key
            __typename
          }
          members {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          projects {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          type
          createdAt
          updatedAt
          studioOwnerId
          __typename
        }
        userID
        owner {
          id
          username
          email
          name
          avatar {
            bucket
            region
            key
            __typename
          }
          registered
          ownProject {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          studio {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        parentID
        parent {
          id
          name
          description
          complexity
          file {
            bucket
            region
            key
            __typename
          }
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          parentID
          parent {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          children {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          components {
            items {
              id
              type
              file {
                bucket
                region
                key
                __typename
              }
              projectID
              project {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              state
              index
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          type
          featured
          createdAt
          updatedAt
          __typename
        }
        children {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        components {
          items {
            id
            type
            file {
              bucket
              region
              key
              __typename
            }
            projectID
            project {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            state
            index
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        featured
        createdAt
        updatedAt
        __typename
      }
      state
      index
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProjectComponents = /* GraphQL */ `
  query ListProjectComponents(
    $filter: ModelProjectComponentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectComponents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        file {
          bucket
          region
          key
          __typename
        }
        projectID
        project {
          id
          name
          description
          complexity
          file {
            bucket
            region
            key
            __typename
          }
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          parentID
          parent {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          children {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          components {
            items {
              id
              type
              file {
                bucket
                region
                key
                __typename
              }
              projectID
              project {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              state
              index
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          type
          featured
          createdAt
          updatedAt
          __typename
        }
        state
        index
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserStudios = /* GraphQL */ `
  query GetUserStudios($id: ID!) {
    getUserStudios(id: $id) {
      id
      studioID
      studio {
        id
        name
        description
        coverImage {
          bucket
          region
          key
          __typename
        }
        members {
          items {
            id
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            user {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        projects {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        owner {
          id
          username
          email
          name
          avatar {
            bucket
            region
            key
            __typename
          }
          registered
          ownProject {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          studio {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        type
        createdAt
        updatedAt
        studioOwnerId
        __typename
      }
      userID
      user {
        id
        username
        email
        name
        avatar {
          bucket
          region
          key
          __typename
        }
        registered
        ownProject {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        studio {
          items {
            id
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            user {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserStudios = /* GraphQL */ `
  query ListUserStudios(
    $filter: ModelUserStudiosFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserStudios(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        studioID
        studio {
          id
          name
          description
          coverImage {
            bucket
            region
            key
            __typename
          }
          members {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          projects {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          type
          createdAt
          updatedAt
          studioOwnerId
          __typename
        }
        userID
        user {
          id
          username
          email
          name
          avatar {
            bucket
            region
            key
            __typename
          }
          registered
          ownProject {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          studio {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const studiosByDate = /* GraphQL */ `
  query StudiosByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studiosByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        coverImage {
          bucket
          region
          key
          __typename
        }
        members {
          items {
            id
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            user {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        projects {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        owner {
          id
          username
          email
          name
          avatar {
            bucket
            region
            key
            __typename
          }
          registered
          ownProject {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          studio {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        type
        createdAt
        updatedAt
        studioOwnerId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const projectsByDate = /* GraphQL */ `
  query ProjectsByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectsByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        complexity
        file {
          bucket
          region
          key
          __typename
        }
        studioID
        studio {
          id
          name
          description
          coverImage {
            bucket
            region
            key
            __typename
          }
          members {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          projects {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          type
          createdAt
          updatedAt
          studioOwnerId
          __typename
        }
        userID
        owner {
          id
          username
          email
          name
          avatar {
            bucket
            region
            key
            __typename
          }
          registered
          ownProject {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          studio {
            items {
              id
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              user {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        parentID
        parent {
          id
          name
          description
          complexity
          file {
            bucket
            region
            key
            __typename
          }
          studioID
          studio {
            id
            name
            description
            coverImage {
              bucket
              region
              key
              __typename
            }
            members {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            projects {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            type
            createdAt
            updatedAt
            studioOwnerId
            __typename
          }
          userID
          owner {
            id
            username
            email
            name
            avatar {
              bucket
              region
              key
              __typename
            }
            registered
            ownProject {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            studio {
              items {
                id
                studioID
                userID
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          parentID
          parent {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          children {
            items {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          components {
            items {
              id
              type
              file {
                bucket
                region
                key
                __typename
              }
              projectID
              project {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              state
              index
              createdAt
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          type
          featured
          createdAt
          updatedAt
          __typename
        }
        children {
          items {
            id
            name
            description
            complexity
            file {
              bucket
              region
              key
              __typename
            }
            studioID
            studio {
              id
              name
              description
              coverImage {
                bucket
                region
                key
                __typename
              }
              members {
                nextToken
                __typename
              }
              projects {
                nextToken
                __typename
              }
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              type
              createdAt
              updatedAt
              studioOwnerId
              __typename
            }
            userID
            owner {
              id
              username
              email
              name
              avatar {
                bucket
                region
                key
                __typename
              }
              registered
              ownProject {
                nextToken
                __typename
              }
              studio {
                nextToken
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            parentID
            parent {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            children {
              items {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            components {
              items {
                id
                type
                projectID
                state
                index
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            type
            featured
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        components {
          items {
            id
            type
            file {
              bucket
              region
              key
              __typename
            }
            projectID
            project {
              id
              name
              description
              complexity
              file {
                bucket
                region
                key
                __typename
              }
              studioID
              studio {
                id
                name
                description
                type
                createdAt
                updatedAt
                studioOwnerId
                __typename
              }
              userID
              owner {
                id
                username
                email
                name
                registered
                createdAt
                updatedAt
                __typename
              }
              parentID
              parent {
                id
                name
                description
                complexity
                studioID
                userID
                parentID
                type
                featured
                createdAt
                updatedAt
                __typename
              }
              children {
                nextToken
                __typename
              }
              components {
                nextToken
                __typename
              }
              type
              featured
              createdAt
              updatedAt
              __typename
            }
            state
            index
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        type
        featured
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

import { Button, Icon } from 'semantic-ui-react'
import { API, Auth, graphqlOperation } from 'aws-amplify'

import { updateProjectComponent } from '../../graphql/mutations'

import { translateDataX } from '../../xAPI/DataX'
import { saveToLRS } from '../../xAPI'

const Toolbar = (props) => {
	const handleDelete = async () => {
		if (window.confirm(`Are you sure you want to delete this ${props.type} component?`)) {
			const result = await API.graphql(
				graphqlOperation(updateProjectComponent, {
					input: {
						id: props.id,
						state: 0
					}
				})
			)
			// HARD DELETE DISABLED
			// await API.graphql(graphqlOperation(deleteProjectComponent, { input: { id: props.id } }))
			logDelete()
			props.onDeleted && props.onDeleted(result)
		}
	}

	const logDelete = async () => {
		const addComponent = translateDataX('remove', 'component', props.id)
		const user = await Auth.currentAuthenticatedUser()
		saveToLRS(user.attributes, addComponent, props.project.id)
	}

	return (
		<div className='dx-toolbar'>
			<Button.Group vertical={!('ontouchstart' in window)}>
				{props.handleMoveItem && props.index !== 0 &&
					<Button
						icon
						onClick={() => props.handleMoveItem(props.index, props.index - 1)}>
						<Icon name={'caret up'} />
					</Button>
				}
				<Button
					icon
					onClick={handleDelete}>
					<Icon name={'trash'} />
				</Button>
				{props.expandEnabled &&
				<Button
					icon
					target='_blank'
					href={`/projects/${props.project.id}/${props.id}`}>
					<Icon name={'expand arrows alternate'} />
				</Button>
				}
				{props.handleMoveItem && props.index !== props.length - 1 &&
					<Button
						icon
						onClick={() => props.handleMoveItem(props.index, props.index + 1)}>
						<Icon name={'caret down'} />
					</Button>
				}
			</Button.Group>
		</div>
	)
}

export default Toolbar
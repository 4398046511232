import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { API, Auth, graphqlOperation, Storage } from 'aws-amplify'
import { Button, Item, Icon, Label, Divider } from 'semantic-ui-react'

import { getProject } from '../../graphql/queries'
import { createProject, createProjectComponent, deleteProject } from '../../graphql/mutations'

import { UserContext } from '../../App'
import ProjectDialog from './ProjectDialog'
import ProjectBlocks from './ProjectBlocks'

import Loading from '../ui/Loading'
import Notification from '../ui/Notification'
import NotFoundPage from '../../pages/NotFoundPage'

import { translateDataX } from '../../xAPI/DataX'
import { saveToLRS } from '../../xAPI'
import aws_exports from '../../aws-exports'

import '../../css/ProjectPage.css'

class ProjectPage extends React.Component {
  state = {
    currentIdentityId: '',
    updateProjectDialog: false,
    project: null,
    isLoading: true,
    isForking: false,
    imgLink: '',
  }

  componentDidMount() {
    this.handleGetProject()
    this.handleGetIdentityId()
  }

  componentWillUnmount() {
    document.title = 'DataX'
  }

  handleGetIdentityId = async () => {
    const { identityId } = await Auth.currentCredentials()
    this.setState({ currentIdentityId: identityId })
  }

  handleGetProject = async () => {
    const result = await API.graphql(graphqlOperation(getProject, { id: this.props.projectId }))

    if (!(result && result.data && result.data.getProject)) {
      this.setState({project: null, isLoading: false})
      return console.error('Could not load project')
    }

    const project = result.data.getProject
    const imgUrlLink = await Storage.get(project.file.key)

    document.title = `${project.name} - DataX`

    this.setState({
      project: project,
      imgLink: imgUrlLink,
      isLoading: false,
    })
  }

  handleForkProject = async (user) => {
    const { project } = this.state
    const blocks =  project.components && project.components.items ? project.components.items : []

    if (!blocks.length && !window.confirm('This project is empty. Are you sure you want to fork it?')) {
      return
    }

    this.setState({ isForking: true })

    try {
      const { identityId } = await Auth.currentCredentials()

      const imageFile = await Storage.get(project.file.key, { download: true })
      const imageFileName = project.file.key.split('/')

      let file = {
        key: '/public/default/codapmini-1.1bf4b74a.png',
        bucket: aws_exports.aws_user_files_s3_bucket,
        region: aws_exports.aws_project_region,
      }
      const uploadedFile = await Storage.put(
        `/public/${identityId}/${Date.now()}-${imageFileName[imageFileName.length - 1]}`,
        imageFile.Body,
        { contentType: imageFile.ContentType }
      )
      if (uploadedFile && uploadedFile.key) {
        file.key = uploadedFile.key
      }

      const user = await Auth.currentAuthenticatedUser()
      const projectInput = {
        name: project.name,
        description: project.description || '',
        file: file,
        userID: user.attributes.sub,
        parentID: project.id,
        type: 'Project'
      }
      if (project.studioID) {
        projectInput.studioID = project.studioID
      }
      const res = await API.graphql(graphqlOperation(createProject, { input: projectInput }))

      const forkedProject = res.data.createProject

      for (let i in blocks) {
        let b = blocks[i]

        const blockFile = await Storage.get(b.file.key, {
          level: 'protected',
          identityId: b.file.key.split('/')[2],
          download: true
        })

        const fileType = b.type === 'DRAW' ? 'tldr' : 'codap'
        const fileData = blockFile && blockFile.Body ? await blockFile.Body.text() : '{}'

        const uploadedFile = await Storage.put(
          `/public/${identityId}/${forkedProject.id}-${Date.now()}.${fileType}`,
          fileData,
          { level: 'protected' }
        )
        if (!uploadedFile || !uploadedFile.key) {
          console.error(`Could not upload .${fileType} file`)
        } else {
          await API.graphql(graphqlOperation(createProjectComponent, {
            input: {
              type: b.type,
              file: {
                key: uploadedFile.key,
                bucket: aws_exports.aws_user_files_s3_bucket,
                region: aws_exports.aws_project_region,
              },
              projectID: forkedProject.id
            }
          }))
        }
      }

      if (forkedProject) {
        saveToLRS(user.attributes, translateDataX('fork', 'project'), forkedProject.id)
        Notification({
          title: 'Success',
          message: 'Project forked successfully!',
          type: 'success'
        })
        this.props.history.push(`/projects/${forkedProject.id}`)
      }
    } catch (err) {
      console.error('Unexpected error occurred', err)
    }

    this.setState({ saving: false })
  }

  handleAddProjectComponent = async (block) => {
    if (block && block.data && block.data.createProjectComponent) {
      const addComponent = translateDataX('add', 'component', block.data.createProjectComponent.id)
      const user = await Auth.currentAuthenticatedUser()
      saveToLRS(user.attributes, addComponent, this.state.project.id)
    }

    this.handleGetProject()
  }

  confirmDeleteProject = async () => {
    if (window.confirm('Are you sure you would like to delete this project? This action cannot be undone')) {
      const res = await API.graphql(graphqlOperation(deleteProject, { input: {
        id: this.state.project.id
      }}))
      if (res && res.data && res.data.deleteProject) {
        const user = await Auth.currentAuthenticatedUser()
        saveToLRS(user.attributes, translateDataX('delete', 'project'), this.state.project.id)
        Notification({
          title: 'Success',
          message: 'Project deleted successfully!',
          type: 'success'
        })
        this.props.history.push(`/profile`)
      } else {
        Notification({
          title: 'Error',
          message: 'An unexpected error occurred',
          type: 'error'
        })
      }
    }
  }

  onCloseDialog = async (project) => {
    const state = { updateProjectDialog: false }
    if (project && project.id === this.props.projectId) {
      const imgUrlLink = await Storage.get(project.file.key)
      state.project = project
      state.imgLink = imgUrlLink
    }
    this.setState(state)
  }

  render() {
    const {
      updateProjectDialog,
      project,
      isLoading,
      isForking,
      imgLink
    } = this.state

    const children = project && project.children && project.children.items ? project.children.items : []

    if (isLoading) {
      return <Loading fullscreen={true} />
    }
    if (!project) {
      return <NotFoundPage title='Project' />
    }
    return (
      <UserContext.Consumer>
        {({ user, userAttributes }) => {
          const isProjectOwner = userAttributes && userAttributes.sub === project.owner.id

          return (
            <div className='project-page-container'>
              <div className='project-container'>
                <Item.Group>
                  <Item>
                    <Item.Image
                      size='tiny'
                      src={imgLink}
                      id='project-img'
                      circular
                    ></Item.Image>
                    <Item.Content>
                      <Item.Header>{ project.name }</Item.Header>
                      <Item.Description><Icon name='user' /> { project.owner.name }</Item.Description>
                      { project.description &&
                        <>
                          <Item.Meta>Description</Item.Meta>
                          <Item.Description>{ project.description }</Item.Description>
                        </>
                      }
                      { project.parent &&
                        <Item.Extra>Forked from <Link to={`/projects/${project.parent.id}`}>{ project.parent.name }</Link></Item.Extra>
                      }
                      <div className='project-buttons'>
                        { isProjectOwner &&
                          <>
                            <Button
                              animated='vertical'
                              onClick={() => this.setState({ updateProjectDialog: true })}>
                              <Button.Content hidden>Edit</Button.Content>
                              <Button.Content visible>
                                <Icon name='edit' />
                              </Button.Content>
                            </Button>
                            <Button
                              animated='vertical'
                              onClick={() => this.confirmDeleteProject()}>
                              <Button.Content hidden>Delete</Button.Content>
                              <Button.Content visible>
                                <Icon name='trash' />
                              </Button.Content>
                            </Button>
                          </>
                        }
                        { !isProjectOwner &&
                          <Button as='div' labelPosition='right'>
                            <Button
                              icon
                              loading={isForking}
                              disabled={isForking}
                              onClick={() => this.handleForkProject(user)}>
                              <Icon name='fork' />
                              Fork
                            </Button>
                            <Label as='a' basic pointing='left'>
                              { children && children.length ? children.length : 0 }
                            </Label>
                          </Button>
                        }
                        {/* Update Project Dialog */}
                        { updateProjectDialog &&
                          <ProjectDialog
                            project={project}
                            onClose={this.onCloseDialog} />
                        }
                      </div>
                    </Item.Content>
                  </Item>
                </Item.Group>
              </div>
              <Divider />
              <ProjectBlocks
                project={ project }
                isOwner={ isProjectOwner }
                user={ userAttributes }
                blocks={ project.components }
                onProjectComponentCreated={ this.handleAddProjectComponent } />
            </div>
          )
        }}
      </UserContext.Consumer>
    )
  }
}

export default withRouter(ProjectPage)

import React, { useState } from 'react'
import { Button, Dialog, DialogContent, DialogActions, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core'
import { DialogHeader } from '../../ui/Dialog'

const CodapDialog = ({ options, onClose, onConfirm }) => {
	const [selected, setSelected] = useState('new')

	return (
		<Dialog
			open
			className='create-studio'
			onClose={ onClose }
			maxWidth='sm'
			fullWidth>
			<DialogHeader
				title={'Add Data Component'}
				onClose={onClose} />
			<DialogContent>
				<p>You can copy the current state of a <b>Data</b> component or start from with an empty component.</p>
				{ options && options.length &&
				<FormControl variant="outlined" fullWidth>
					<InputLabel
						style={{background: 'white', padding: '0 10px'}}
						>Component</InputLabel>
					<Select
						variant="outlined"
						onChange={e => setSelected(e.target.value)}
						defaultValue="new">
						<MenuItem value="new">Empty Component</MenuItem>
						{ options.map((_, index) => <MenuItem key={ index } value={ index }>{ index + 1 }</MenuItem> )}
					</Select>
				</FormControl>
				}
			</DialogContent>
			<DialogActions>
				<Button
					autoFocus
					onClick={() => onConfirm({ codapSelected: selected === 'new' ? 'new' : options[selected] }) && onClose()}
					>{'Add Component'}</Button>
			</DialogActions>
		</Dialog>
	)
}

export default CodapDialog

import React from 'react'
import { Link } from 'react-router-dom'
import { AmplifyS3Image } from '@aws-amplify/ui-react/legacy'
import { Card } from 'semantic-ui-react'

import { UserContext } from '../App'

import StudioDialog from './studio/StudioDialog'

import '../css/v2/Card.css'

const initialState = {
  name: "",
  tags: ["Arts", "Web Dev", "Tech", "Crafts", "Entertainment"],
  selectedTags: [],
  options: [],
  addStudioDialog: false,
  value: "",
  description: "",
  imagePreview:
    "https://www.prestophoto.com/storage/static/landing/pdf-book-printing/upload-icon.png",
  image: "",
  createNew: false,
  addMembers: [],
  limitItemsStudio: 4,

  percentUploaded: 0,
  isUploading: false
};

class ProfileStudios extends React.Component {
  state = {
    name: "",
    tags: ["Arts", "Web Dev", "Tech", "Crafts", "Entertainment"],
    selectedTags: [],
    options: [],
    addStudioDialog: false,
    value: "",
    description: "",
    imagePreview:
      "https://www.prestophoto.com/storage/static/landing/pdf-book-printing/upload-icon.png",
    image: "",
    createNew: false,
    addMembers: [],
    limitItemsStudio: 4,

    percentUploaded: 0,
    isUploading: false
  };

  handleKeyPress = e => {
    if (e.key === "Enter") {
      this.setState({
        addMembers: [...this.state.addMembers, this.state.value],
        value: ""
      });
    }
  };

  handleTagClose(tag) {
    var newMembers = this.state.addMembers;
    newMembers = newMembers.filter(ele => {
      return ele !== tag;
    });
    this.setState({ addMembers: newMembers });
    // return newMembers;
  }

  onLoadMore = () => {
    this.setState({
      limitItemsStudio: this.state.limitItemsStudio + 10
    });
  };

  render() {
    const {
      addStudioDialog,
      limitItemsStudio
    } = this.state;

    var studioData = this.props.studioItems;

    return (
      <UserContext.Consumer>
        {({ user }) => (
          <div>
            {studioData.length === 0 ? (
              <Card
                className="create-first-project ui link"
                onClick={() =>
                  this.setState({
                    ...initialState,
                    addStudioDialog: true
                  })
                }
              >
                <p className="add-first-project">+</p>
              </Card>
            ) : (
              <div>
                <Card.Group className="prof-project-list prof-studio-list">
                  <Card
                    className="create-project ui link"
                    onClick={() =>
                      this.setState({
                        ...initialState,
                        addStudioDialog: true
                      })
                    }
                  >
                    {/* <i className="add icon add-project"></i> */}
                    <p className="add-project">+</p>
                  </Card>

                  {studioData.slice(0, limitItemsStudio).map((stu, index) => {
                    return (
                      <Card className="item-card ui link" key={stu.studio.id}>
                        <Link to={`/studios/${stu.studio.id}`} className="s-image">
                          <AmplifyS3Image
                            imgKey={stu.studio.coverImage.key}
                            wrapped
                            theme={{
                              photoImg: {
                                maxWidth: "100%"
                              }
                            }}
                          />
                        </Link>
                        <Link to={`/studios/${stu.studio.id}`}>
                          <Card.Content>
                            <Card.Header>
                              <span className="card-header-text">
                                {stu.studio.name}{" "}
                              </span>
                              <span className="count-text">
                                <i className="user outline icon"></i>
                                {stu.studio.members && stu.studio.members.items ? stu.studio.members.items.length : 0}
                              </span>
                            </Card.Header>
                            <Card.Meta>
                              {stu.studio.owner.name}
                            </Card.Meta>
                          </Card.Content>
                        </Link>
                      </Card>
                    );
                  })}
                </Card.Group>
                <div className="load-button">
                  {studioData.length !== 0 ? (
                    limitItemsStudio < studioData.length ? (
                      <button
                        className="ui button profile-button"
                        onClick={this.onLoadMore}
                      >
                        Load More
                      </button>
                    ) : (
                      <button className="ui button disabled profile-button">
                        No More Studios
                      </button>
                    )
                  ) : (
                    <div>
                      <p></p>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Studio Modal */}
            { addStudioDialog &&
            <StudioDialog
              onClose={ () => { this.setState({ addStudioDialog: false }) } }
              />
            }
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default ProfileStudios;

// Activities and Verbs are defined in:
// * https://id.ourdatax.org/activities
// * https://id.ourdatax.org/activity/{{ activity }}
// * https://id.ourdatax.org/verbs
// * https://id.ourdatax.org/verb/{{ verb }}
import activities from './activities.json'
import verbs from './verbs.json'

export const translateCodap = action => {
	const dict = findTranslation(action)
	if (dict && dict.ignore) return

	const verb = dict && dict.verb && verbs[dict.verb] ? verbs[dict.verb] : verbs['unknown']
	const activity = dict && dict.activity && activities[dict.activity] ? activities[dict.activity] : activities['component']

	if (action && activity.definition) {
		activity.definition.extensions = {
			'https://codap.ourdatax.org': action
		}
	}

	return {
		verb: verb,
		object: activity
	}
}

const findTranslation = event => {
	if (event) {
		let dict = {}
		if (event.name === 'game.create' && event.message.indexOf('Importer') >= 0) {
			return { 'verb': 'import', 'activity': 'codap' }
		}
		if (['UNDO', 'REDO'].includes(event.action)) {
			dict.verb = event.action.toLowerCase()
		} else if (verbDictionary[event.name]) {
			if (event.message === 'Expanded component') {
				dict.verb = 'unhide'
			} else {
				dict.verb = verbDictionary[event.name]
			}
		}
		if (event.component && event.component.type && componentDictionary[event.component.type]) {
			dict.activity = componentDictionary[event.component.type]
		} else if (event.message.indexOf('calcView') >= 0) {
			// No good way to pass in component info so defaulting to reading the string
			dict.activity = 'calculator'
		} else if (event.name.indexOf('caseTable') >= 0) {
			// Between DataContext, DataTable, and Collection, its hard to keep track of all the places table is edited in CODAP
			dict.activity = 'table'
		}
		if (dict.verb || dict.activity) {
			return dict
		}
	}
	return null
}

const verbDictionary = {
	// TABLE
	'dataContext.create': 'create',
	'component.toggle.delete': 'close',
	'caseTable.editCellValue': 'edit',
	'caseTable.insertCases': 'add',
	'caseTable.createNewCase': 'add',
	'caseTable.createAttribute': 'add',
	'caseTable.resizeColumns': 'resize',
	'data.deleteCases': 'delete',
	'toggle.toCaseCard': 'configure',
	'toggle.toCaseTable': 'configure',
	// GRAPH
	'graphComponent.create': 'create',
	'axis.attributeChange': 'configure',
	'axis.rescaleFromData': 'configure',
	'graph.display.showAllCases': 'configure',
	'graph.display.displayOnlySelected': 'configure',
	'graph.display.enableNumberToggle': 'configure',
	'graph.display.disableNumberToggle': 'configure',
	'graph.display.enableMeasuresForSelection': 'configure',
	'graph.display.disableMeasuresForSelection': 'configure',
	'graph.toggleCount': 'configure',
	'data.style.pointSizeChanged': 'configure',
	'data.style.changePointColor': 'configure',
	'data.style.changeStrokeColor': 'configure',
	'plot.strokeSameAsFillChange': 'configure',
	'data.style.changeBackgroundColor': 'configure',
	'plot.transparencyChange': 'configure',
	'graph.addBackgroundImage': 'configure',
	// MAP
	'map.create': 'create',
	'map.fitBounds': 'zoom',
	'map.pan': 'zoom',
	// SLIDER
	'sliderComponent.create': 'create',
	'slider.change': 'edit',
	// CALCULATOR
	'component.toggle.add': 'create', // something else?
	'calculator.calculate': 'compute',
	// TEXT
	'textComponent.create': 'create',
	'textComponent.edit': 'edit',
	// CODAP
	'component.titleChange': 'rename',
	'component.resize': 'resize',
	'component.minimize': 'hide',
	'component.move': 'move',
	'component.close': 'close',
	'webView.show': 'help' // Only seems to be used for Help
}

const componentDictionary = {
	'DG.Collection': 'collection',
	'DG.DataContext': 'collection', // should this be different?
	'DG.TableView': 'table',
	'DG.GraphView': 'graph',
	'DG.MapView': 'map',
	'DG.SliderView': 'slider',
	'DG.Calculator': 'calculator',
	'DG.TextView': 'text',
	'DG.WebView': 'webview' // Only seems to be used for Help
}

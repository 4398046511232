import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'

import logo from '../assets/logo.png'

import '../css/Navbar.css'

class Navbar extends React.Component {
  render() {
    const { user, onClickLogin, handleSignout } = this.props
    return (
      <header className='ui secondary menu navbar-style'>
        <Link to='/' className='item'>
          <img className='data-logo' src={logo} alt='logo'></img>
          <p className='logo-text'>DataX</p>
        </Link>
        { user &&
        <div className='right menu'>
          <Link to='/profile' className='item'>
            <p className='nav-text'>{user.displayName}</p>
          </Link>
          <button
            className='ui item nav-text'
            onClick={handleSignout}>
            Logout&nbsp;<Icon name='sign-out' />
          </button>
        </div>
        }
        { !user &&
        <div className='right menu'>
          <button className='ui button nav-button' onClick={onClickLogin}>Sign in</button>
        </div>
        }
      </header>
    )
  }
}

export default withRouter(Navbar)

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Storage } from 'aws-amplify'
import { Tldraw, TldrawApp, useFileSystem } from '@tldraw/tldraw'

import { translateDraw } from '../../xAPI/Draw'
import { saveToLRS } from '../../xAPI'

import Loading from '../ui/Loading'
import Notification from '../ui/Notification'
import Toolbar from '../dx/Toolbar'

const Draw = (props) => {
	const [drawFile, setDrawFile] = useState()
	const [loading, setLoading] = useState(true)
	const [deleted, setDeleted] = useState(false)
	const drawRef = useRef()
	const fileSystemEvents = useFileSystem()
	let autoSaveTimer

	const handleOnMount = useCallback(app => {
		drawRef.current = app
	}, [])

	const handleOnPersist = (e) => {
		if (!(drawRef && drawRef.current && drawRef.current.document)) return

		autoSaveTimer && clearTimeout(autoSaveTimer)
		autoSaveTimer = setTimeout(() => {
			if (drawRef && drawRef.current && drawRef.current.document) {
				handleSaveFile(JSON.stringify(drawRef.current.document))
			}
		}, 5000)
	}

	useEffect(() => {
		const { file } = props

		const getFile = async () => {
			const res = await Storage.get(file.key, {
				level: 'protected',
				identityId: file.key.split('/')[2],
				download: true
			})

			if (res && res.Body) {
				res.Body.text()
					.then(text => {
						let document
						try {
							document = JSON.parse(text)
						} catch (e) {
							console.error('Could not parse file. Use defaultDocument')
						}
						setDrawFile(document && document.name ? document : TldrawApp.defaultDocument)
						setLoading(false)
					})
					.catch(err => console.error('Unexpected error', err))
			}
		}

		getFile()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleSaveFile = async (content) => {
		const { isOwner, file } = props
		if (!isOwner) {
			return
		}

		const res = await Storage.put(file.key, content, {
			level: 'protected',
			identityId: file.key.split('/')[2],
		})

		if (res && res.key) {
			Notification({
				title: 'Success',
				message: 'Changes saved',
				type: 'success',
			})
		}
	}

	const logEvent = (cmd, reason) => {
		if (!reason) return

		saveToLRS(props.user, translateDraw(reason, cmd), props.project.id)
	}

	if (deleted) return null

	return (
		<div
			className="dx-component"
			style={{
				width: 'calc(100% - 6em)',
				height: 'calc(100vh - 2em)',
				maxHeight: '600px'
			}}>
			{ props.isOwner &&
			<Toolbar
				id={props.id}
				type='draw'
				project={props.project}
				index={props.index}
        length={props.length}
        handleMoveItem={props.handleMoveItem}
        onDeleted={() => setDeleted(true)} />
			}
			{ loading && <Loading /> }
			{ !loading &&
				<Tldraw
					// For read only
					readOnly={!props.isOwner}
					showStyles={props.isOwner}
					showZoom={props.isOwner}
					// general
					document={drawFile}
					showMenu={false}
					autofocus={false}
					showPages={false}
					onChange={logEvent}
					onCommand={logEvent}
					onMount={handleOnMount}
					onPersist={handleOnPersist}
					{...fileSystemEvents}
					/>
			}
		</div>
	)
}

export default Draw
// Activities and Verbs are defined in:
// * https://id.ourdatax.org/activities
// * https://id.ourdatax.org/activity/{{ activity }}
// * https://id.ourdatax.org/verbs
// * https://id.ourdatax.org/verb/{{ verb }}
import activities from './activities.json'
import verbs from './verbs.json'

export const translateText = (action) => {
	if ('saved' !== action) return
	return {
		verb: verbs.edit,
		object: activities.content
	}
}
